import { faMapMarkerAlt, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TextField from 'components/commons/TextField/TextField';
import React, {
  ChangeEventHandler,
  FormEventHandler,
  KeyboardEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { AgentResponse, cityResponse } from 'src/common/apiSchemas';
import { useFormMachine } from 'src/context/FormMachineProvider';
import { debounce } from 'src/utils';
import { dropdownSearchKWAgent } from 'src/utils/fetchActions';
import sendGaEvent from 'src/utils/gtag';
import styles from './search-drop-down.module.scss';

interface SearchDropDownProps {
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onSubmit?: FormEventHandler;
}
const placeholder = '/img/mortgage-application/defaultAgentImage.svg';

const SearchDropDown = ({ onChange, onSubmit }: SearchDropDownProps) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isSearchTermInvalid, setIsSearchTermInvalid] = useState<boolean>(
    false
  );
  const [, send] = useFormMachine();
  const [abortController, setAbortController] = useState<
    AbortController | undefined
  >();
  const [data, setData] = useState<any[]>([]);
  const [cursor, setCursor] = useState<number>(-1);
  const [loading, setLoading] = useState<boolean>(false);
  const [noResults, setNoResults] = useState<boolean>(false);
  const dropDownRef = useRef<HTMLDivElement>(null!);
  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);
  const isMountedRef = useRef<boolean | null>(null);

  useEffect(() => {
    isMountedRef.current = true;
    setAbortController(new AbortController());
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearchAgent = useCallback<(value: string) => void>(
    debounce((value: string) => {
      if (!isMountedRef.current) return;
      setNoResults(false);
      setLoading(true);
      if (value.length >= 3) {
        abortController?.abort();
        const newController = new AbortController();
        setAbortController(newController);
        dropdownSearchKWAgent(value, newController.signal).then(searchData => {
          if (!isMountedRef.current) return;
          if ('data' in searchData) {
            setLoading(searchData.loading);
            setData(searchData.data);
            setNoResults(searchData.data.length === 0 && !searchData.loading);
          }
        });
      }
    }, 400),
    [abortController]
  );
  const handleSelectDropDown = (
    item: AgentResponse | cityResponse | string
  ) => {
    if (typeof item !== 'string' && 'first_name' in item) {
      send({
        type: 'SELECT_AGENT',
        selectedAgent: {
          firstName: item.first_name,
          lastName: item.last_name,
          phone: item.mobile_phone,
          KWUID: item.id,
          photo: item.photo,
          email: item.email,
          loanManagerId: '',
        },
        marketCenter: {
          id: item.org_key,
          name: item.org_name,
        },
      });
    } else if (typeof item !== 'string' && 'state' in item) {
      send({ type: 'SEARCH_BY_CITY', state: item.state, city: item.city });
    } else if (typeof item === 'string') {
      send({ type: 'SEARCH', searchTerm: item });
    }
    sendGaEvent('agentSearchModalSearch', 'mortgageRate');
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    const elements = dropDownRef.current?.childNodes;

    // early exit if no data is loaded
    if (data.length <= 0) return;

    if (e.key === 'ArrowUp' && cursor > 0) {
      setCursor(prevState => {
        (elements?.[prevState - 1] as HTMLElement).focus();
        return prevState - 1;
      });
    } else if (e.key === 'ArrowDown' && cursor < data.length) {
      setCursor(prevState => {
        (elements?.[prevState + 1] as HTMLElement).focus();
        return prevState + 1;
      });
    } else if (e.key === 'Enter') {
      if (searchTerm.length < 3) setIsSearchTermInvalid(true);
      handleSelectDropDown(data[cursor - 1] || searchTerm);
    } else if (e.key === 'Escape') {
      setData([]);
      inputRef.current?.focus();
    }
  };

  return (
    <div
      className={styles.searchDropdownWrapper}
      onKeyDown={handleKeyDown}
      role="presentation"
    >
      <form
        onSubmit={e => {
          e.preventDefault();
          if (searchTerm.length < 3) {
            setIsSearchTermInvalid(true);
            return;
          }
          if (onSubmit) onSubmit(e);
        }}
      >
        <TextField
          type="text"
          name="agent"
          placeholder="Search by Name, City or State"
          size="medium"
          cypressTag="agentNameInput"
          value={searchTerm}
          errorText={
            isSearchTermInvalid
              ? 'Please enter a least 3 Charactes to search'
              : ''
          }
          ref={inputRef}
          preventBlurStateChange
          icon={
            <FontAwesomeIcon
              className={styles.faArrowCircleRight}
              icon={faSearch}
            />
          }
          clearable
          onChange={e => {
            if (onChange) onChange(e);
            debounceSearchAgent(e.target.value);
            setSearchTerm(e.target.value);
          }}
          onClear={() => {
            setData([]);
            setSearchTerm('');
          }}
          label="agent"
          showLabel={false}
        />
      </form>
      <div
        className={`${styles.dropdown} ${
          loading || data.length >= 0 ? styles.dropdownVisible : ''
        }`}
        ref={dropDownRef}
      >
        {loading && searchTerm.length >= 3 && (
          <p className={styles.alternativeText}>Loading...</p>
        )}
        {noResults && (
          <p className={styles.alternativeText}>No results found </p>
        )}
        {!loading && data.length > 0 && (
          <div
            className={styles.searchFor}
            tabIndex={0}
            role="menuitem"
            onClick={() => handleSelectDropDown(searchTerm)}
            onKeyPress={e =>
              e.key === 'enter' && handleSelectDropDown(searchTerm)
            }
          >
            <span className={styles.searchIcon} data-cy="searchIcon">
              <FontAwesomeIcon icon={faSearch} size="xs" />
            </span>
            Search for {searchTerm}
          </div>
        )}
        {data &&
          !loading &&
          data.map(item =>
            !item.first_name ? (
              <div
                className={styles.cityOption}
                onClick={() => handleSelectDropDown(item)}
                tabIndex={0}
                role="menuitem"
                key={item.state}
                onKeyPress={e =>
                  e.key === 'enter' && handleSelectDropDown(item)
                }
              >
                <FontAwesomeIcon
                  icon={faMapMarkerAlt}
                  size="sm"
                  className={styles.mapMarker}
                />
                {item.city}, {item.state}
              </div>
            ) : (
              <div
                onClick={() => handleSelectDropDown(item)}
                onKeyPress={e =>
                  e.key === 'enter' && handleSelectDropDown(item)
                }
                className={styles.agentOption}
                tabIndex={0}
                role="menuitem"
                key={item.id}
                aria-label={`${item.first_name} ${item.last_name}`}
              >
                <img
                  src={item.photo || placeholder}
                  className={styles.agentImage}
                  alt={`${item.first_name}_${item.last_name}`}
                  onError={e => {
                    (e.target as HTMLImageElement).src = placeholder;
                  }}
                />
                <div data-cy="agentOption">
                  <p>
                    {item.first_name.toLowerCase()}{' '}
                    {item.last_name.toLowerCase()}
                    <span>
                      {item.city && item.state
                        ? `${item.city.toLowerCase()}, ${item.state}`
                        : ''}
                    </span>
                  </p>
                  <div className={styles.role}>{item.orgs[0].role_name}</div>
                </div>
              </div>
            )
          )}
      </div>
    </div>
  );
};

export default SearchDropDown;
