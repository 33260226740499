import React from 'react';
import { OptionTypeBase } from 'react-select';
/* eslint-disable camelcase */
import gaActions from 'src/utils/gaActions';
import { SCXML, State, StateValue } from 'xstate';
import { AgentResponse, cityResponse, Quote } from './apiSchemas';

export type GaPage =
  | 'home'
  | 'faq'
  | 'forAgents'
  | 'forBorrowers'
  | 'legal'
  | 'lokcinvite'
  | 'mortgageRate'
  | 'offerReady'
  | 'shared';

export type KeysOfUnion<T> = T extends T ? keyof T : never;

export type GaEvent = KeysOfUnion<typeof gaActions[GaPage]>;

export enum Origin {
  ORGANIC = 'organic',
  AGENT_GENERATED = 'agentGenerated',
  BUY_DROPDOWN = 'buyDropdown',
  REFINANCE_DROPDOWN = 'refinanceDropdown',
  REFINANCE_CAlCULATOR = 'refinanceCalculator',
  AFFORDABILITY_CAlCULATOR = 'affordabilityCalculator',
  MORTGAGE_CAlCULATOR = 'mortgageCalculator',
}

export enum CalculatorType {
  AFFORDABILITY = 'affordabilityCalculator',
  MORTGAGE = 'mortgageCalculator',
  REFINANCE = 'refinanceCalculator',
  TOTAL_COST = 'totalCost',
}

export type QuotesPageVersion = 'checklist' | 'quotes' | 'checklistquotes';
export type SliderType = 'general' | 'article';

export type Campaigns = number;

export type ParsedQueryParams = {
  utm_source: string;
  utm_medium: string;
  utm_campaign: string;
  utm_term: string;
  utm_content: string;
  campaign_id: string;
  kwuid: string;
  propstate: string;
  is_buyer_agent: string;
  is_seller_agent: string;
  team_id: string;
  loan_mgr_from_preapp: string;
  loan_manager_id: string;
  buyorrefinance: string;
  aba: string;
};

// UTM
export interface UTM {
  source: string;
  medium: string;
  campaign: string;
  term: string;
  content: string;
}

export type ProcessOptions = 'Purchase' | 'Refinance' | 'CashOut';

// Form Answers
export enum GetAgentInfo {
  HAS_AGENT = 'has KW agent',
  BUYING_FROM_AGENT = 'buying from KW agent',
  NO_AGENT = 'no KW agent',
  HAS_MARKETCENTER = 'has KW Market Center',
  SKIPPED_KW_AGENT = 'skipped KW agent',
}

export enum BuyOrRefinance {
  BUY = 'buy',
  REFINANCE = 'refinance',
}

export enum HomeType {
  SINGLE_FAMILY = 'single-family',
  MULTI_FAMILY = 'multi-family',
  CONDO = 'condo',
  TOWNHOME = 'townhome',
  OTHER = 'other',
}

export enum PropertyUse {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SEASONAL = 'seasonal',
  RENTAL = 'rental',
}

export enum PropertyAddress {
  YES = 'yes',
  NONE = 'none',
}

export enum PurchaseDate {
  NINE_ZERO = '90',
  ONE_TWO_ZERO = '120',
  THREE_SIX_FIVE = '365',
  NINE_NINE_NINE = '999',
}

// Agent
export interface Agent {
  firstName: string;
  lastName: string;
  phone: string;
  KWUID: string;
  photo: string;
  email: string;
  loanManagerId: '' | number;
  loanManagerFirstName?: '' | string;
  loanManagerLastName?: '' | string;
  loanManagerEmail?: '' | string;
  loanManagerPhoto?: '' | string;
}
export interface MarketCenter {
  id: string;
  name: string;
}
export interface Team {
  teamID: string;
  teamName: string;
  teamPhone: string;
  teamEmail: string;
}

// User info
export interface UserInfo {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  dateOfBirth?: string;
  state?: string;
}

// Address
export interface Address {
  address: string;
  unit: string;
  street: string;
  streetNumber: string;
  state: string;
  city: string;
  county: string;
  zip: { number: string; valid: boolean };
  country: string;
}
export interface DwellingDetails {
  noOfStories: string;
  totalBathCount: string;
  numberOfBedrooms: string;
  yearBuilt: string;
  squareFeet: string;
  replacementCost: string;
  fireHydrantDistanceAnswer: string;
  fireStationDistanceAnswer: string;
}

export enum PropAddress {
  YES = 'Yes',
  NO_WITH_ZIP = 'No with zip',
  NO_WITHOUT_ZIP = 'No w/o zip',
}

// Rates

export type Liens = {
  fips: string;
  apn: string;
  amount: number;
  due_date: string;
  event_type: string;
  grantee_1: string;
  grantee_1_forenames: string;
  grantee_2?: any;
  grantee_2_forenames?: any;
  grantor_1: string;
  grantor_2?: any;
  interest_rate: number;
  record_book: string;
  record_date: string;
  record_doc?: any;
  record_page: string;
  mortgage_years: number;
  thirty_yr: number;
  fifteen_yr: number;
  hc_interest_rate: number;
  lien_type?: any;
  heloc: boolean;
  stand_alone_refi: boolean;
  lender_type: string;
  arm_index: string;
}[];

export interface Rates {
  kwRate: number;
  salesPayment: number;
  downPayment: number;
  downPaymentPercent: number;
  creditsCoreRange: number;
  loanTerm: number;
}

// Terms of service
export interface TermsOfService {
  isAccepted: boolean;
  dateAccepted: string;
}

export interface JvInfo {
  legalName: string;
  name: string;
  id: number;
  jvPath: string;
  email: string;
  imageName: string;
}

export interface Quotes {
  elapsedTime: number;
  isChecklist: boolean;
  ownsHomeInsurance: boolean;
  quoteRequestID: any;
  quoteSelected: any;
  quotesList: Quote[] | null;
  quotesLoading: boolean;
  quotesStatus: string | null;
  saveForLater: boolean;
}

// Modals
export enum Modals {
  AGENT_SEARCH = 'agentSearch',
  AGENT_RESULTS = 'agentResults',
  AGENT_NOT_FOUND = 'agentNotFound',
  NO_KW_AGENT = 'noKWAgent',
  SKIP_AGENT = 'skipAgent',
  SAVE_PROGRESS = 'saveProgress',
  SAVE_PROGRESS_CONFIRMATION = 'saveProgressConfirmation',
  LOADING = 'loading',
  FLOW_LOADING = 'flowLoading',
  FLOW_COMPLETED = 'flowCompleted',
  ERROR = 'error',
  CONTACT_CONFIRMATION = 'contactUsConfirmation',
  QUOTE_DETAIL = 'quoteDetails',
  FULL_PAGE_LOADER = 'fullPageLoader',
}
export enum AvatarSize {
  SM = '48px',
  MD = '64PX',
  LG = '88PX',
  XL = '204px',
}

// Machines
export interface FormContext {
  flowInfo: {
    origin: Origin;
    sessionID: string;
    authToken: string;
    campaignID: Campaigns;
    urlHasCampaignId: boolean;
    loanFromPreApp: string;
    isBuyerAgent: boolean;
    isSellerAgent: boolean;
    flowCompleted: boolean;
    isLeadSubmitted: boolean;
    error: string | null;
    aba: 'on' | 'off';
  };
  utm: UTM;
  answers: {
    getAgentInfo: GetAgentInfo | '';
    buyOrRefinance: BuyOrRefinance | '';
    homeType: HomeType | '';
    propertyUse: PropertyUse | '';
    propertyAddress: PropertyAddress | '';
    purchaseDate: PurchaseDate | '';
    askingPrice: string;
  };
  agent: Agent & OutsideAgent;
  marketCenter: MarketCenter;
  team: Team;
  userInfo: UserInfo;
  address: Address;
  dwellingDetails: DwellingDetails;
  liens: Liens;
  rates: Rates;
  termsOfService: TermsOfService;
  activeModal: Modals | '';
  propAddressOrNot: PropAddress | '';
  quotes: Quotes;
  kellerCovered: {
    lead: string;
    sessionID: number | null;
    accessToken: string | null;
    refreshToken: string | null;
    sourceIdentifier: string;
    contactID: number | null;
    updatedAt: string | null;
    contextChanged: boolean;
    allCallsFinished: boolean;
    isDisclaimerWaitingForCalls: boolean;
    quotePageVersion: string;
  };

  validUserInfo: boolean;
  activePage: string;
  lastState: string;
  redirectUrl: Location | '';
  saveProgress: any;
  isLoadingToSplash: boolean;
  jvInfo: JvInfo;
}

export interface AgentSearchContext {
  agents: AgentResponse[] | null;
  selectedAgent: Agent | null;
  marketCenter: MarketCenter | null;
  searchTerm: string | null;
  error: string | null;
  city: string | null;
  state: string | null;
}

export interface NoAgentContext {
  answer: GetAgentInfo | '';
  marketCenter: MarketCenter;
}

export interface AgentInfoContext {
  answer: GetAgentInfo | null;
  selectedAgent: Agent | null;
  marketCenter: MarketCenter | null;
  campaignID: Campaigns | null;
}

export interface SaveProgressContext {
  email?: string;
  link?: string;
  error?: string;
}

// Machines Schemas
export interface FormSchema {
  states: {
    idle: {
      states: {
        initSession: {};
        initSessionFailure: {};
        interpretQueryParams: {};
        loadQueryParams: {};
        interpretQueryParamsSuccess: {};
      };
    };
    getAgentInfo: {};
    buyOrRefinance: {};
    homeType: {};
    propertyUse: {};
    propertyAddress: {};
    purchaseDate: {};
    askingPrice: {};
    currentAddress: {};
    doesThisLookRight: {};
    getUserInfo: {
      states: {
        idle: {};
        fetchLo: {};
        fetchOptimalBlue: {};
        fetchOptimalBlueSuccess: {};
        fetchOptimalBlueFailure: {};
      };
    };
    disclaimer: {};
    submitData: {
      states: {
        idle: {};
        postReferral: {};
        postPreapproval: {};
        updateFinalSession: {};
        postPreapprovalSuccess: {};
        postPreapprovalFailure: {};
      };
    };
    finished: {};
  };
}

export interface AgentSearchSchema {
  states: {
    idle: {};
    loading: {};
    failure: {};
    success: {};
    agentNotFound: {};
    agentSelected: {};
  };
}

export interface NoAgentSchema {
  states: {
    unset: {};
    agent: {};
    skipped: {};
    finished: {};
  };
}

export interface AgentInfoSchema {
  states: {
    unset: {};
    hasAgent: {};
    noAgent: {};
    finished: {};
  };
}

export interface SaveProgressSchema {
  states: {
    idle: {};
    loading: {};
    success: {};
    failure: {};
    finished: {};
  };
}

// Machines Events
// Machines Events - Form Machine

export interface deselectAnswerEvent {
  type: 'DESELECT_ANSWER';
}
export interface buyEvent {
  type: 'BUY';
  value: BuyOrRefinance.BUY;
}

export interface refinanceEvent {
  type: 'REFINANCE';
  value: BuyOrRefinance.REFINANCE;
}

export interface returnHomeEvent {
  type: 'RETURN_HOME';
}

export interface loadQueryParamsEvent {
  type: 'LOAD_QUERY_PARAMS';
}

export interface noQueryParamsEvent {
  type: 'NO_QUERY_PARAMS_DATA';
}

export interface setAddressEvent {
  type: 'SET_ADDRESS';
  address: Address;
}
export interface setUnitEvent {
  type: 'SET_UNIT';
  unit: string;
}

export interface clearAddressEvent {
  type: 'CLEAR_ADDRESS';
}
export interface nextEvent {
  type: 'NEXT';
}

export interface backEvent {
  type: 'BACK';
}

export interface saveEvent {
  type: 'SAVE';
}
export interface homeTypeEvent {
  type: 'SET_HOME_TYPE';
  homeType: HomeType;
}
export interface propertyUseEvent {
  type: 'SET_PROPERTY_USE';
  value: PropertyUse;
}

export interface setZipEvent {
  type: 'SET_ZIP';
  zip: string;
  state?: string;
}

export interface clearZipEvent {
  type: 'CLEAR_ZIP';
}

export interface setZipYesEvent {
  type: 'YES';
}

export interface setZipNoEvent {
  type: 'NO';
}

export interface purchaseDateEvent {
  type: 'SET_PURCHASE_DATE';
  purchaseDate: PurchaseDate;
}

export interface setAnswerEvent {
  type: 'SET_ANSWER';
}

export interface setDwellingDetailsEvent {
  type: 'SET_DWELLING_DETAILS';
  details: DwellingDetails;
}

export interface setAskingPriceEvent {
  type: 'SET_ASKING_PRICE';
  askingPrice: string;
}

export interface setLiensEvent {
  type: 'SET_LIENS';
  liens: Liens;
}

export interface saveUserInfoEvent {
  type: 'SET_USER_INFO';
  userInfo: UserInfo & { state?: string };
}

export interface validateUserInfoEvent {
  type: 'VALIDATE_USER_INFO';
}

export interface fetchLoEvent {
  type: 'FETCH_LO';
}

export interface submitEvent {
  type: 'SUBMIT';
}

export interface submitNoSplashEvent {
  type: 'SUBMIT_NO_SPLASH';
}

export interface submitFormEvent {
  type: 'SUBMIT_FORM';
}

// disclaimer
export interface setElapsedTimeEvent {
  type: 'SET_ELAPSED_TIME';
  time: number;
}

export interface saveQuotesEvent {
  type: 'SAVE_QUOTES';
  quotes: Quote[];
}

export interface setQuotesStatusEvent {
  type: 'SET_QUOTES_STATUS';
  status: string;
}

export interface goToQuotesEvent {
  type: 'GO_TO_QUOTES';
}

export interface submitDataEvent {
  type: 'SUBMIT_DATA';
}

// Quotes Events

export interface selectQuoteEvent {
  type: 'SELECT_QUOTE';
  quote?: Quote;
  ownsInsurance: boolean;
}

export interface checklistNextEvent {
  type: 'CHECKLIST_NEXT';
}

export interface backToChecklistEvent {
  type: 'BACK_TO_CHECKLIST';
}

// other events
export interface changeModalEvent {
  type: 'CHANGE_MODAL';
  modal: string;
}

export interface updateSessionEvent {
  type: 'UPDATE_SESSION';
}

export interface setOriginBuyEvent {
  type: 'SET_ORIGIN_BUY';
  origin: Origin;
  property: string;
  value: string;
}
export interface setOriginRefinanceEvent {
  type: 'SET_ORIGIN_REFINANCE';
  origin: Origin;
  property: string;
  value: string;
}

export interface clearAnswersEvent {
  type: 'CLEAR_ANSWERS';
}

export interface returnNoAgentEvent {
  type: 'RETURN_NO_AGENT';
  lastState: string;
}

export interface clearLastStateEvent {
  type: 'CLEAR_LAST_STEP';
  lastState: string;
}

export interface goCloseModalToGetAgentEvent {
  type: 'ClOSE_MODAL_AND_GO_LAST_STEP';
}

export type navigateToHomeEvent = {
  type: 'NAVIGATE_TO_HOME';
};

export interface invokeGetAgentEvent {
  type: 'INVOKE_GET_AGENT';
}

export interface goToCurrentEvent {
  type: 'GO_TO_CURRENT';
}

export interface startFlowEvent {
  type: 'START_FLOW';
  loanManagerId?: string;
  campaignId?: number;
  partnerId?: number;
  aba?: 'on' | 'off';
}

export interface setLoIdEvent {
  type: 'SET_LO';
  loanManagerId: string;
  loanManagerLastName: string;
  loanManagerFirstName: string;
  loanManagerEmail: string;
  loanManagerPhoto: string;
}
export interface removeAgent {
  type: 'REMOVE_AGENT';
}

export type buyOrRefinanceEvents =
  | buyEvent
  | refinanceEvent
  | setUnitEvent
  | setAddressEvent
  | clearAddressEvent;

export type homeTypeEvents = homeTypeEvent;

export type propertyUseEvents = propertyUseEvent;

export type propertyAddressEvents =
  | setZipEvent
  | clearZipEvent
  | setZipYesEvent
  | setZipNoEvent;

export type purchaseDateEvents = purchaseDateEvent;

export type doesThisLookRightEvents =
  | setDwellingDetailsEvent
  | setAskingPriceEvent
  | setLiensEvent;

export type getUserInfoEvents =
  | saveUserInfoEvent
  | validateUserInfoEvent
  | fetchLoEvent;

export type submitDataEvents =
  | submitEvent
  | submitFormEvent
  | submitNoSplashEvent;

export type disclaimerEvents =
  | setElapsedTimeEvent
  | saveQuotesEvent
  | setQuotesStatusEvent
  | goToQuotesEvent
  | submitDataEvent;

export type quotesEvents =
  | selectQuoteEvent
  | checklistNextEvent
  | backToChecklistEvent;

export type globalEvents =
  | changeModalEvent
  | updateSessionEvent
  | setOriginBuyEvent
  | setOriginRefinanceEvent
  | clearAnswersEvent
  | returnNoAgentEvent
  | clearLastStateEvent
  | navigateToHomeEvent
  | goCloseModalToGetAgentEvent
  | invokeGetAgentEvent
  | goToCurrentEvent
  | startFlowEvent
  | setLoIdEvent
  | removeAgent
  | setAgentEvent
  | setOutsideAgentEvent;

// Machines Transitions - Search Agent Machine
export interface searchEvent {
  type: 'SEARCH';
  searchTerm: string;
}

export interface searchAgainEvent {
  type: 'SEARCH_AGAIN';
}

export interface searchByCityEvent {
  type: 'SEARCH_BY_CITY';
  city: string;
  state: string;
}
export interface selectAgentEvent {
  type: 'SELECT_AGENT';
  selectedAgent: Agent;
  marketCenter: MarketCenter;
}

export interface setAgentEvent {
  type: 'SET_AGENT';
  selectedAgent: Agent;
}

export interface setOutsideAgentEvent {
  type: 'SET_OUTSIDE_AGENT';
  selectedAgent: OutsideAgent;
}

export interface agentNotFoundEvent {
  type: 'AGENT_NOT_FOUND';
}

export interface searchAgainAgentNotFoundEvent {
  type: 'SEARCH_AGAIN_AGENT_NOT_FOUND';
}

export interface continueAgentNotFound {
  type: 'CONTINUE_AGENT_NOT_FOUND';
}

export type AgentSearchTransitions =
  | searchEvent
  | searchAgainEvent
  | selectAgentEvent
  | agentNotFoundEvent
  | searchAgainAgentNotFoundEvent
  | continueAgentNotFound
  | searchByCityEvent;

// Machines Transitions - No Agent Machine
export interface noAgentAgentEvent {
  type: 'AGENT';
}

export interface noAgentListingEvent {
  type: 'LISTING';
}

export interface noAgentSkipEvent {
  type: 'SKIP';
}

export interface noAgentCancelEvent {
  type: 'CANCEL';
}

export interface noAgentContinueEvent {
  type: 'CONTINUE';
}

export interface noAgentMissDiscountEvent {
  type: 'MISS_DISCOUNT';
}

export interface noAgentBackEvent {
  type: 'BACK';
}

export type NoAgentTransitions =
  | noAgentAgentEvent
  | noAgentListingEvent
  | noAgentSkipEvent
  | noAgentCancelEvent
  | noAgentContinueEvent
  | noAgentMissDiscountEvent
  | noAgentBackEvent;

// Machines Transitions - Agent Info Machine
export interface hasAgentEvent {
  type: 'HAS_AGENT';
}

export interface noAgentEvent {
  type: 'NO_AGENT';
}

export interface cancelHasAgentEvent {
  type: 'CANCEL_HAS_AGENT';
}

export interface cancelNoAgentEvent {
  type: 'CANCEL_NO_AGENT';
}

export interface backAgentInfoEvent {
  type: 'BACK';
}

export interface activateModalAgentInfoEvent {
  type: 'ACTIVATE_MODAL';
}

export interface activateHasAgentEvent {
  type: 'ACTIVATE_HAS_AGENT';
}

export type AgentInfoTransitions =
  | hasAgentEvent
  | noAgentEvent
  | cancelHasAgentEvent
  | cancelNoAgentEvent
  | backAgentInfoEvent
  | activateModalAgentInfoEvent
  | activateHasAgentEvent;

// Machines Transitions - Save Progress Machine

export interface saveProgressEvent {
  type: 'SAVE_PROGRESS';
  email: string;
  link: string;
}

export interface closeSaveProgressEvent {
  type: 'CLOSE';
}

export type SaveProgressTransitions =
  | saveProgressEvent
  | closeSaveProgressEvent;

// Machines Types
export type FormMachineType = State<FormContext, any>;

export type FormEvent =
  | deselectAnswerEvent
  | returnHomeEvent
  | loadQueryParamsEvent
  | noQueryParamsEvent
  | nextEvent
  | backEvent
  | saveEvent
  | buyOrRefinanceEvents
  | homeTypeEvents
  | propertyUseEvents
  | propertyAddressEvents
  | purchaseDateEvents
  | setAnswerEvent
  | doesThisLookRightEvents
  | getUserInfoEvents
  | submitDataEvents
  | disclaimerEvents
  | quotesEvents
  | globalEvents
  | AgentSearchTransitions
  | NoAgentTransitions
  | AgentInfoTransitions
  | SaveProgressTransitions;

// State to save
export interface StateToSave {
  _event: SCXML.Event<any>;
  context: FormContext;
  children: {};
  value: StateValue;
}

// URL parameters
export interface ParsedURLParameters {
  utm_source: string;
  utm_medium: string;
  utm_campaign: string;
  utm_term: string;
  utm_content: string;
}

export interface ContactUsFormData {
  fullname: string;
  message: string;
  email: string;
  state: string;
}

export interface TestimonialsData {
  name: string;
  title?: string;
  location: string;
  testimonial: string;
}

export interface ContactUsFormDataExternal extends ContactUsFormData {
  recieverEmail: string;
}

export interface SearchKWAgentData {
  data: Array<AgentResponse | cityResponse>;
  loading: boolean;
}

export interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

export interface Education {
  title: string;
  previewImg: string;
  desc?: string;
  findMoreLink?: string;
}
export interface GeneralCard {
  title: string;
  Icon: React.FunctionComponent;
  description: string;
  href: string;
}
export interface Inputs {
  label: string;
  type:
    | 'accountingInput'
    | 'percentageInput'
    | 'toggleInput'
    | 'selectBox'
    | 'yearSelect';
  toolTip?: string;
  description?: string;
  options?: Option[];
  step?: number;
  name?: string;
}

export interface Option extends OptionTypeBase {
  label: string;
  value: string;
}

export interface HeaderGraph {
  title: string;
  prefix: string;
  sufix: string;
  number?: number;
  type?: 'number' | 'date';
  date?: string;
  subText?: string;
}
export interface Chart {
  headers: Record<string, string>;
  labels: Record<
    | 'Principal_Interest'
    | 'Property_Taxes'
    | 'Homeowners_Insurance'
    | 'Mortgage_Insurance',
    string
  >;
  upTo: string;
  amortizationTable: string;
  warning: string;
}

export interface LoanOfficer {
  salesforceId: string;
  kwid: string;
  firstName: string;
  lastName: string;
  slug: string;
  slns: string[];
  nmls: string;
  address: string;
  city: string;
  state: string;
  experienceYears: number;
  photo: string;
  biography: string;
  languages: string[];
  specialties: string[];
  email: string;
  socialLinks?: {
    type: 'facebook' | 'mail' | 'phone';
    url?: string;
    number?: string;
    toolTip?: string;
  }[];
}

export interface FullLoanOfficersResponse {
  officers: LoanOfficer[];
}
export interface BlogPost {
  title: string;
  readTime: string;
  author: string;
  authorImage: string;
  subtitle1: string;
  subtitle2?: string;
  subtitle3?: string;
  subtitle4?: string;
  subtitle5?: string;
  subtitle6?: string;
  image1: string;
  image2?: string;
  image3?: string;
  image4?: string;
  image5?: string;
  table1?: {
    highlightedColumn?: number;
    columns: { title: string; dataIndex: string }[];
    data?: any[];
  };
  socialLinks?: {
    type: 'facebook' | 'instagram' | 'linkedin' | 'twitter';
    url: string;
  }[];
  text1: string;
  text2?: string;
  text3?: string;
  text4?: string;
  text5?: string;
  text6?: string;
  question?: string;
  answer?: string;
  quote?: string;
  image3class?: string;
}

// Calculators Forms

export type MortgageForm = {
  loanTermInYears: { value: number; label: string; loanType: string };
  downPaymentPercent: string;
  yearlyPropertyTaxPercent: string;
  creditScore: { value: string; label: string };
  yearlyPropertyTax: string;
  yearlyMortgageInsurance: string;
  yearlyInterestRate: string;
  yearlyHomeInsurance: string;
  yearlyHOAFee: string;
  purchasePrice: number;
  downPayment: string;
};

export type OutsideAgent = {
  name: string;
  email: string;
  agencyAffiliation: string;
};

export interface GuestInvite {
  email: string;
  phoneNumber: string;
}

export interface GuestInviteResponse {
  client: string;
  phone_number: string;
  message: string;
  topics: string[];
  loan_officer: string;
  agent: string;
  method: string;
  emails: string[];
}

export interface MortgageInvite {
  affiliateAgency?: string;
  agentEmail?: string;
  agentName?: string;
  contactMethod: 'Email' | 'Phone';
  email: string;
  guests: GuestInvite[];
  loanOfficerId: string;
  loanOfficerEmail: string;
  loanOfficerName: string;
  message: string;
  name: string;
  outsideAgent: boolean;
  phoneNumber: string;
  sendCopyDesktop: boolean;
  sendCopyMobile: boolean;
  toLearn: string[];
  toTalk: string[];
}
