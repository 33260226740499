import React from 'react';
import Loader from 'components/commons/Loader/Loader';
import styles from './full-page-loader.module.scss';

const FullPageLoader = () => {
  return (
    <div className={styles.wrapper}>
      <Loader />
    </div>
  );
};

export default FullPageLoader;
