import { FormContext, purchaseDateEvents } from 'src/common/types';
import { ActionFunctionMap, assign } from 'xstate';

export const purchaseDateNode = {
  initial: 'idle',
  states: {
    idle: {
      on: {
        SET_PURCHASE_DATE: {
          target: 'ready',
          actions: { type: 'setPurchaseDate' },
        },
      },
    },
    ready: {
      on: {
        SET_PURCHASE_DATE: {
          target: 'ready',
          actions: { type: 'setPurchaseDate' },
        },
        NEXT: {
          target: '#innerSteps.askingPrice.hist',
        },
        DESELECT_ANSWER: {
          target: 'idle',
          actions: { type: 'clearPurchaseDate' },
        },
      },
    },
    hist: { type: 'history' as 'history' },
  },
  on: {
    BACK: {
      target: '#steps.innerSteps.propertyAddress.hist',
    },
  },
};

export const purchaseDateActions: ActionFunctionMap<
  FormContext,
  purchaseDateEvents
> = {
  setPurchaseDate: assign({
    answers: (context, event) => ({
      ...context.answers,
      purchaseDate: event.purchaseDate,
    }),
  }),
  clearPurchaseDate: assign({
    answers: context => ({
      ...context.answers,
      purchaseDate: '',
    }),
  }),
};
