import { FormContext } from 'src/common/types';
import { ActionFunctionMap, assign } from 'xstate';

export const askingPriceNode = {
  id: 'askingPrice',
  initial: 'idle',
  on: {
    BACK: '#steps.innerSteps.purchaseDate.hist',
  },
  states: {
    idle: {
      on: {
        SET_ASKING_PRICE: {
          actions: 'setAskingPrice',
          target: 'ready',
        },
      },
    },
    ready: {
      on: {
        SET_ASKING_PRICE: {
          actions: 'setAskingPrice',
        },
        NEXT: {
          target: '#innerSteps.getUserInfo',
          cond: 'hasAskingPrice',
        },
      },
    },
    hist: {
      type: 'history' as 'history',
      target: 'idle',
    },
  },
};

export const askingPriceActions: ActionFunctionMap<FormContext, any> = {
  setAskingPrice: assign({
    answers: (ctx, event) => ({
      ...ctx.answers,
      askingPrice: event.askingPrice,
    }),
  }),
  unsetBuyOrRefinance: assign({
    answers: (context, _event) => ({
      ...context.answers,
      buyOrRefinance: '' as '',
    }),
  }),
};

export const askingPriceGuards = {
  hasAskingPrice: (context: FormContext) => {
    return Boolean(context.answers.askingPrice);
  },
};
