import React from 'react';

const NoMatches = () => {
  return (
    <svg
      width="230px"
      height="145px"
      viewBox="0 0 230 145"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="No Matches"
    >
      <title>8D531B5C-766F-481C-9BE3-64EC9C09424D</title>
      <defs>
        <polygon
          id="path-1"
          points="0 0.624911504 230 0.624911504 230 145 0 145"
        />
      </defs>
      <g
        id="Updates-11/24/20"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="KM_NoResults_00" transform="translate(-605.000000, -198.000000)">
          <g
            id="select-agent-modal"
            transform="translate(120.000000, 117.000000)"
          >
            <g id="Group-62" transform="translate(485.000000, 81.000000)">
              <g id="Group-3">
                <mask id="mask-2" fill="white">
                  <use xlinkHref="#path-1" />
                </mask>
                <g id="Clip-2" />
                <path
                  d="M82.6703056,29.3580088 C111.022917,17.469292 139.668139,5.49845133 168.402806,1.34349558 C173.756694,0.568451327 179.215361,0.080840708 184.104139,1.70920354 C188.992917,3.33756637 193.28625,7.42451327 194.400472,13.5170796 C195.234861,18.0749558 194.270139,23.4014602 195.726806,27.6026106 C197.854306,33.7285398 204.216361,35.2183186 209.96125,35.3492035 C215.706139,35.4788053 222.018361,35.0309735 226.163472,39.1397345 C231.499472,44.4264602 230.906583,55.7544248 226.619639,64.1220796 C222.33525,72.4910177 215.293417,78.3936726 208.283528,83.1812389 C194.905194,92.3162389 180.703972,98.6230973 166.581972,104.787522 C118.292194,125.868982 68.5368056,146.174115 20.31475,144.947389 C15.2419722,144.817788 10.0426944,144.394336 5.91036111,141.522566 C1.77675,138.64823 -1.03819444,132.717345 0.363527778,126.424602 C3.00725,114.556416 18.2255833,107.754248 17.98025,96.0567257 C17.7553611,85.3138938 4.24413889,83.8831416 2.97147222,73.4675221 C2.30958333,68.0588938 5.45930556,62.0009735 9.48175,58.3477434 C13.5029167,54.6945133 18.2524167,52.9301327 22.8639167,51.274823 C46.1526944,42.9123009 69.4619167,34.8962389 82.6703056,29.3580088"
                  id="Fill-1"
                  fill="#CEF6F7"
                  mask="url(#mask-2)"
                />
              </g>
              <path
                d="M180,41 L180,63.3009645 C180,70.3127727 174.401627,76 167.499368,76 C167.222671,76 166.944711,75.9897319 166.673068,75.9717628 C163.551069,75.7638344 160.741143,74.3917635 158.660231,72.2803917 C156.773892,70.3628296 155.483904,67.8317503 155.112448,65.0080311 C155.039167,64.4497048 155,63.8798269 155,63.3009645 L155,41 L180,41 Z"
                id="Fill-4"
                fill="#FFFFFF"
              />
              <path
                d="M180,41 L180,63.3009645 C180,70.3127727 174.401627,76 167.499368,76 C167.222671,76 166.944711,75.9897319 166.673068,75.9717628 C163.551069,75.7638344 160.741143,74.3917635 158.660231,72.2803917 C156.773892,70.3628296 155.483904,67.8317503 155.112448,65.0080311 C155.039167,64.4497048 155,63.8798269 155,63.3009645 L155,41 L180,41 Z"
                id="Stroke-6"
                stroke="#000000"
                strokeWidth="2.654"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M167,76.9729026 L167,77 L130,77 L130,37.7667684 C130,31.0285367 135.168083,25.5135611 141.723443,25.0348396 C142.030306,25.0129035 142.338448,25 142.650425,25 C146.143548,25 149.305515,26.4297129 151.594201,28.7394476 C153.884166,31.0504727 155.14614,34.2415196 155.14614,37.7667684 L155.187055,41.8146108 L155.187055,64.2345219 C155.187055,64.8164719 155.226692,65.3893893 155.30085,65.9506936 C155.676757,68.7894737 156.982203,71.334053 158.891147,73.2618427 C160.996994,75.384476 163.84059,76.7638651 167,76.9729026"
                id="Fill-8"
                fill="#17A85F"
              />
              <path
                d="M167,76.9729026 L167,77 L130,77 L130,37.7667684 C130,31.0285367 135.168083,25.5135611 141.723443,25.0348396 C142.030306,25.0129035 142.338448,25 142.650425,25 C146.143548,25 149.305515,26.4297129 151.594201,28.7394476 C153.884166,31.0504727 155.14614,34.2415196 155.14614,37.7667684 L155.187055,41.8146108 L155.187055,64.2345219 C155.187055,64.8164719 155.226692,65.3893893 155.30085,65.9506936 C155.676757,68.7894737 156.982203,71.334053 158.891147,73.2618427 C160.996994,75.384476 163.84059,76.7638651 167,76.9729026 Z"
                id="Stroke-10"
                stroke="#000000"
                strokeWidth="2.654"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M75.4124523,25 C69.1098253,25 64,30.1311176 64,36.4600073 L64,116 L129.327856,116 L129.327856,37.6130387 C129.327856,30.9390066 134.473325,25.4755296 141,25 L75.4124523,25 Z"
                id="Fill-12"
                fill="#FFFFFF"
              />
              <path
                d="M75.4124523,25 C69.1098253,25 64,30.1311176 64,36.4600073 L64,116 L129.327856,116 L129.327856,37.6130387 C129.327856,30.9390066 134.473325,25.4755296 141,25 L75.4124523,25 Z"
                id="Stroke-14"
                stroke="#000000"
                strokeWidth="2.654"
                strokeLinecap="round"
              />
              <path
                d="M106,57.4993407 C106,55.5663967 107.566102,54 109.5,54 C111.43258,54 113,55.5663967 113,57.4993407 C113,59.4322848 111.43258,61 109.5,61 C107.566102,61 106,59.4322848 106,57.4993407"
                id="Fill-16"
                fill="#000000"
              />
              <path
                d="M79,57.4993407 C79,55.5663967 80.5661017,54 82.5,54 C84.43258,54 86,55.5663967 86,57.4993407 C86,59.4322848 84.43258,61 82.5,61 C80.5661017,61 79,59.4322848 79,57.4993407"
                id="Fill-18"
                fill="#000000"
              />
              <path
                d="M90.3345155,66 C89.477754,63.3342357 90.2828714,60.1847991 92.564479,58.3948405 C94.8447624,56.6036448 98.5419465,56.4848915 100.758668,58.3453599 C102.974065,60.2045913 103.310414,62.9210732 102.779407,65.4272627"
                id="Stroke-20"
                stroke="#000000"
                strokeWidth="2.654"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <g id="Group-25" transform="translate(54.000000, 42.000000)">
                <path
                  d="M0.017375,0.724625 C1.371125,2.078375 2.724875,3.432125 4.077375,4.784625"
                  id="Fill-22"
                  fill="#000000"
                />
                <path
                  d="M0.017375,0.724625 C1.371125,2.078375 2.724875,3.432125 4.077375,4.784625"
                  id="Stroke-24"
                  stroke="#000000"
                  strokeWidth="2.654"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <path
                d="M51,51.167 C52.6666667,51.389 54.3333333,51.611 56,51.833 C55.8043478,51.741 55.6074879,51.649 51,51.167"
                id="Fill-26"
                fill="#000000"
              />
              <path
                d="M51,51.167 C52.6666667,51.389 54.3333333,51.611 56,51.833 C55.8043478,51.741 55.6074879,51.649 51,51.167 Z"
                id="Stroke-28"
                stroke="#000000"
                strokeWidth="2.654"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <g id="Group-33" transform="translate(51.000000, 58.000000)">
                <path
                  d="M0.46788,3.07293333 C1.94148,2.2076 3.41268,1.34093333 4.88628,0.474266667"
                  id="Fill-30"
                  fill="#000000"
                />
                <path
                  d="M0.46788,3.07293333 C1.94148,2.2076 3.41268,1.34093333 4.88628,0.474266667"
                  id="Stroke-32"
                  stroke="#000000"
                  strokeWidth="2.654"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <g id="Group-37" transform="translate(74.000000, 80.000000)">
                <line
                  x1="27.1612727"
                  y1="1.42245"
                  x2="0.441636364"
                  y2="1.42245"
                  id="Fill-34"
                  fill="#FFFFFF"
                />
                <line
                  x1="27.1612727"
                  y1="1.42245"
                  x2="0.441636364"
                  y2="1.42245"
                  id="Stroke-36"
                  stroke="#17A85F"
                  strokeWidth="2.654"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <path
                d="M94,97.4808942 C94,107.708261 102.291739,116 112.519106,116 C114.426827,116 116.26859,115.710798 118,115.174251 L94.8257492,92 C94.2904709,93.7314095 94,95.5731727 94,97.4808942"
                id="Fill-38"
                fill="#CEF6F7"
              />
              <path
                d="M131,97.9232303 C131,87.472682 122.528614,79 112.07677,79 C109.575274,79 107.187836,79.4912263 105,80.3738784 L129.626122,105 C130.51007,102.81346 131,100.426022 131,97.9232303"
                id="Fill-40"
                fill="#17A85F"
              />
              <path
                d="M126,109.132359 L99.8663701,83 C97.1410185,85.2690077 95.0741241,88.3032494 94,91.7760388 L117.223961,115 C120.695479,113.925876 123.730992,111.858981 126,109.132359"
                id="Fill-42"
                fill="#17A85F"
              />
              <path
                d="M131,103.346437 L107.692754,81 C102.745293,82.3202043 98.1315546,84.280082 94,86.7864273 L120.80308,114 C124.311602,110.888455 128.773072,107.299389 131,103.346437"
                id="Fill-44"
                fill="#CEF6F7"
              />
              <g id="Group-49" transform="translate(74.000000, 89.000000)">
                <line
                  x1="17.746"
                  y1="0.9186"
                  x2="0.439533333"
                  y2="0.9186"
                  id="Fill-46"
                  fill="#FFFFFF"
                />
                <line
                  x1="17.746"
                  y1="0.9186"
                  x2="0.439533333"
                  y2="0.9186"
                  id="Stroke-48"
                  stroke="#17A85F"
                  strokeWidth="2.654"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <g id="Group-53" transform="translate(74.000000, 98.000000)">
                <line
                  x1="16.7461769"
                  y1="0.9146"
                  x2="0.453638462"
                  y2="0.9146"
                  id="Fill-50"
                  fill="#FFFFFF"
                />
                <line
                  x1="16.7461769"
                  y1="0.9146"
                  x2="0.453638462"
                  y2="0.9146"
                  id="Stroke-52"
                  stroke="#17A85F"
                  strokeWidth="2.654"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <g id="Group-57" transform="translate(74.000000, 106.000000)">
                <line
                  x1="21.0537412"
                  y1="1.41075"
                  x2="0.4488"
                  y2="1.41075"
                  id="Fill-54"
                  fill="#FFFFFF"
                />
                <line
                  x1="21.0537412"
                  y1="1.41075"
                  x2="0.4488"
                  y2="1.41075"
                  id="Stroke-56"
                  stroke="#17A85F"
                  strokeWidth="2.654"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <path
                d="M123.817013,108.07269 C118.12735,114.510531 108.363654,115.054211 102.012994,109.287596 C95.6610626,103.52098 95.1246378,93.6252308 100.814301,87.1873894 C106.503963,80.749548 116.266388,80.2084446 122.618319,85.9737719 C128.968979,91.7403875 129.507947,101.634849 123.817013,108.07269 M127.871978,80.0293652 C118.281159,71.3214664 103.540915,72.1434281 94.9492192,81.8626748 C86.3575233,91.5844982 87.1672452,106.522815 96.7593354,115.230714 C105.091717,122.795854 117.30873,123.169473 126.005931,116.762552 C126.134317,116.914576 126.275415,117.061447 126.426681,117.198011 L145.721452,134.716876 C146.743456,135.643194 148.040028,136.063193 149.307363,135.992334 C150.577241,135.922764 151.817882,135.361047 152.73311,134.326509 C154.562293,132.256144 154.390688,129.073941 152.347951,127.220018 L133.053181,109.701153 C132.900643,109.562013 132.74175,109.437043 132.580314,109.321093 C137.907699,99.8595143 136.203088,87.5932169 127.871978,80.0293652"
                id="Fill-58"
                fill="#FFFFFF"
              />
              <path
                d="M123.817013,108.07269 C118.12735,114.510531 108.363654,115.054211 102.012994,109.287596 C95.6610626,103.52098 95.1246378,93.6252308 100.814301,87.1873894 C106.503963,80.749548 116.266388,80.2084446 122.618319,85.9737719 C128.968979,91.7403875 129.507947,101.634849 123.817013,108.07269 Z M127.871978,80.0293652 C118.281159,71.3214664 103.540915,72.1434281 94.9492192,81.8626748 C86.3575233,91.5844982 87.1672452,106.522815 96.7593354,115.230714 C105.091717,122.795854 117.30873,123.169473 126.005931,116.762552 C126.134317,116.914576 126.275415,117.061447 126.426681,117.198011 L145.721452,134.716876 C146.743456,135.643194 148.040028,136.063193 149.307363,135.992334 C150.577241,135.922764 151.817882,135.361047 152.73311,134.326509 C154.562293,132.256144 154.390688,129.073941 152.347951,127.220018 L133.053181,109.701153 C132.900643,109.562013 132.74175,109.437043 132.580314,109.321093 C137.907699,99.8595143 136.203088,87.5932169 127.871978,80.0293652 Z"
                id="Stroke-60"
                stroke="#000000"
                strokeWidth="2.654"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default NoMatches;
