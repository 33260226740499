import { useActor } from '@xstate/react';
import Modal from 'components/commons/Modal/Modal';
import React from 'react';
import {
  AgentInfoContext,
  AgentInfoTransitions,
  AgentSearchContext,
  AgentSearchTransitions,
} from 'src/common/types';
import { useFormMachine } from 'src/context/FormMachineProvider';
import sendGaEvent from 'src/utils/gtag';
import { ActorRef, State } from 'xstate';
import lang from 'src/data/lang/mortgageApplication/agent-results-modal.lang';
import { AgentResponse } from 'src/common/apiSchemas';
import SearchDropDown from 'components/mortgage-application/SearchDropDown/SearchDropDown';
import AgentCard from 'components/mortgage-application/AgentCard/AgentCard';
import SecondaryButton from 'components/commons/SecondaryButton/SecondaryButton';
import PrimaryButton from 'components/commons/PrimaryButton/PrimaryButton';
import NoMatches from 'components/commons/svg/NoMatches';
import styles from './agent-results-modal.module.scss';

const AgentResultsModal = () => {
  const [current, send] = useFormMachine();
  const { getAgentInfo } = current.children;
  const [agentInfoState] = useActor<
    ActorRef<
      AgentInfoTransitions,
      State<
        AgentInfoContext,
        AgentInfoTransitions,
        any,
        { value: any; context: AgentInfoContext }
      >
    >
  >(getAgentInfo);

  const { agentSearch } = agentInfoState.children;
  const [agentSearchState] = useActor<
    ActorRef<
      AgentSearchTransitions,
      State<
        AgentSearchContext,
        AgentSearchTransitions,
        any,
        { value: any; context: AgentSearchContext }
      >
    >
  >(agentSearch);
  const loading = agentSearchState.matches('loading');
  const success = agentSearchState.matches('success');
  const { searchTerm, agents: results } = agentSearchState.context;
  const {
    agentNotFound,
    searchAgain,
    loadingLabel,
    noMatches: labelNoMatches,
    tryAgain,
  } = lang;

  const handleClose = () => {
    send('CANCEL_HAS_AGENT');
    sendGaEvent('agentResultsModalClose', 'mortgageRate');
  };
  const handleAgentNotFound = () => {
    send('AGENT_NOT_FOUND');
    sendGaEvent('agentResultsCantFind', 'mortgageRate');
  };

  const handleSearchAgain = () => {
    send('SEARCH_AGAIN');
    sendGaEvent('agentResultsSearchAgain', 'mortgageRate');
  };
  const handleSelectAgent = (agent: AgentResponse) => {
    send({
      type: 'SELECT_AGENT',
      selectedAgent: {
        firstName: agent.first_name,
        lastName: agent.last_name,
        phone: agent.mobile_phone,
        KWUID: agent.id,
        photo: agent.photo,
        email: agent.email,
        loanManagerId: '',
      },
      marketCenter: {
        id: agent.org_key,
        name: agent.org_name,
      },
    });
  };
  return current.matches('steps.getAgentInfo') ? (
    <Modal isOpen className={styles.dialog} onRequestClose={handleClose}>
      <div className={styles.wrapper}>
        {loading && <p className={styles.agentResults}>{loadingLabel}</p>}
        {success && results && results?.length > 0 ? (
          <>
            <div className={styles.searchInputContainer}>
              <SearchDropDown />
            </div>

            <div className={styles.agentResults}>
              {results.map(agent => (
                <AgentCard
                  agent={agent}
                  key={`${agent.id}-${agent.first_name}${agent.last_name}${agent.city}`}
                  handleSelect={handleSelectAgent}
                />
              ))}
            </div>
          </>
        ) : (
          success && (
            <div className={styles.noMatchesContainer}>
              <NoMatches />
              <p className={styles.noMatchesTitle}>
                {labelNoMatches}
                <br /> <b> {searchTerm}</b>
              </p>
              <p className={styles.noMatchesSubtitle}>{tryAgain}</p>
            </div>
          )
        )}
        <div
          className={`${styles.actionsContainer} ${
            !loading && !results?.length ? styles.noMatchActionsContainer : ''
          }`}
        >
          {!loading && !results?.length && (
            <>
              <PrimaryButton
                customClass={styles.searchAgain}
                handleOnClick={handleSearchAgain}
                type="button"
              >
                {searchAgain}
              </PrimaryButton>
              <SecondaryButton
                handleOnClick={() => handleAgentNotFound()}
                type="button"
                cypressTag="agentNotFound"
              >
                {agentNotFound}
              </SecondaryButton>
            </>
          )}
          {!loading && results && results?.length > 0 && (
            <button
              onClick={() => handleAgentNotFound()}
              type="button"
              data-cy="agentNotFound"
              className={styles.agentNotFound}
            >
              {agentNotFound}
            </button>
          )}
        </div>
      </div>
    </Modal>
  ) : null;
};

export default AgentResultsModal;
