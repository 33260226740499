import BasicTooltip from 'components/toolips/basic/BasicToolTip';
import React from 'react';
import InfoSVG from '../svg/info';
import styles from './label.module.scss';

interface LabelProps {
  errorText?: string;
  labelExtraInfo?: string | React.ReactElement | null;
  customClass?: string;
  name?: string;
  label?: string;
  showLabel?: boolean;
  tooltip?: string;
}

const Label = ({
  label,
  errorText,
  labelExtraInfo,
  customClass,
  name,
  tooltip,
  showLabel,
}: LabelProps) => {
  if (!label && !errorText) {
    return null;
  }
  return (
    <div
      className={`${styles.labelWrapper} ${customClass} ${
        !showLabel && !errorText && styles.invisible
      }`}
      data-testid={`label-${name}`}
    >
      <div className={styles.labelContainer}>
        <label
          className={`${styles.label} ${!showLabel && styles.visuallyHidden}`}
          htmlFor={name}
        >
          {label}
          {labelExtraInfo}
        </label>
        {tooltip ? (
          <BasicTooltip icon={<InfoSVG />}>
            <p className={styles.tooltipText}>{tooltip}</p>
          </BasicTooltip>
        ) : null}
        {errorText ? <p className={styles.errorLabel}>{errorText}</p> : null}
      </div>
    </div>
  );
};

Label.defaultProps = {
  errorText: '',
  labelExtraInfo: null,
  customClass: '',
  name: '',
  tooltip: '',
  showLabel: true,
};

export default Label;
