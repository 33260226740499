import { FormContext, GetAgentInfo, Origin } from 'src/common/types';

const formContext: FormContext = {
  flowInfo: {
    origin: Origin.ORGANIC,
    sessionID: '',
    authToken: '',
    campaignID: 46,
    urlHasCampaignId: false,
    loanFromPreApp: '',
    isBuyerAgent: false,
    isSellerAgent: false,
    flowCompleted: false,
    isLeadSubmitted: false,
    error: null,
    aba: 'on',
  },
  utm: {
    source: '',
    medium: '',
    campaign: '',
    term: '',
    content: '',
  },
  answers: {
    getAgentInfo: GetAgentInfo.SKIPPED_KW_AGENT,
    buyOrRefinance: '',
    homeType: '',
    propertyUse: '',
    propertyAddress: '',
    purchaseDate: '',
    askingPrice: '',
  },
  agent: {
    firstName: '',
    lastName: '',
    phone: '',
    KWUID: '',
    photo: '',
    email: '',
    loanManagerId: '',
    loanManagerFirstName: '',
    loanManagerPhoto: '',
    loanManagerLastName: '',
    loanManagerEmail: '',
    agencyAffiliation: '',
    name: '',
  },
  marketCenter: {
    id: '',
    name: '',
  },
  team: {
    teamID: '',
    teamName: '',
    teamPhone: '',
    teamEmail: '',
  },
  userInfo: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  },
  address: {
    address: '',
    unit: '',
    street: '',
    streetNumber: '',
    state: '',
    city: '',
    county: '',
    zip: { number: '', valid: false },
    country: '',
  },
  dwellingDetails: {
    noOfStories: '',
    totalBathCount: '',
    numberOfBedrooms: '',
    yearBuilt: '',
    squareFeet: '',
    replacementCost: '',
    fireHydrantDistanceAnswer: '',
    fireStationDistanceAnswer: '',
  },
  liens: [],
  rates: {
    kwRate: 0,
    salesPayment: 0,
    downPayment: 0,
    downPaymentPercent: 20,
    creditsCoreRange: 780,
    loanTerm: 15,
  },
  termsOfService: {
    isAccepted: false,
    dateAccepted: '',
  },
  kellerCovered: {
    lead: 'ORGANIC',
    sessionID: null,
    accessToken: null,
    refreshToken: null,
    sourceIdentifier: '2460811077',
    contactID: null,
    updatedAt: null,
    contextChanged: false,
    allCallsFinished: false,
    isDisclaimerWaitingForCalls: false,
    quotePageVersion: 'checklistquotes',
  },
  quotes: {
    elapsedTime: 0,
    isChecklist: false,
    ownsHomeInsurance: false,
    quoteRequestID: null,
    quoteSelected: null,
    quotesList: null,
    quotesLoading: false,
    quotesStatus: null,
    saveForLater: false,
  },
  activeModal: '',
  propAddressOrNot: '',
  validUserInfo: false,
  activePage: '',
  lastState: '',
  redirectUrl: '',
  saveProgress: '',
  isLoadingToSplash: false,
  jvInfo: {
    legalName: 'Keller Mortgage, LLC',
    name: 'Keller Mortgage',
    id: 102100,
    jvPath: 'keller-mortgage',
    email: 'Optout@kellermortgage.com',
    imageName: 'km',
  },
};

export default formContext;
