const getTodaysDate = () => {
  const todayDate = new Date();
  const month = todayDate.getMonth() + 1;
  const year = todayDate.getFullYear();
  const day = todayDate.getDay();
  return `${year}-${month}-${day}`;
};

const monthDiff = (dateFromInput: string, dateToInput: string) => {
  const dateFrom = new Date(dateFromInput);
  const dateTo = new Date(dateToInput);

  return (
    dateTo.getMonth() -
    dateFrom.getMonth() +
    12 * (dateTo.getFullYear() - dateFrom.getFullYear())
  );
};

const addYears = (originalDate: string, yearsToadd: number) => {
  const d = new Date(originalDate);
  const year = d.getFullYear() + yearsToadd;
  const month = d.getMonth() + 1;
  const day = d.getDate() + 1;
  return `${year}-${month}-${day}`;
};

export const amortizationCalculator = (
  amountFinanced: number,
  recordDate: string,
  term: number,
  apr: number
) => {
  const futureDate = addYears(recordDate, term);
  const totalPeriods = monthDiff(recordDate, futureDate);
  const periodsPassed = monthDiff(recordDate, getTodaysDate());

  const r = apr / 100 / 12;
  const n = totalPeriods;
  const p = amountFinanced;

  const remainingBalance = Math.round(
    p * (((1 + r) ** n - (1 + r) ** periodsPassed) / ((1 + r) ** n - 1))
  );
  return remainingBalance;
};
