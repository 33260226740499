import React from 'react';

const InfoSVG = () => {
  return (
    <svg
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.49992 0.0830078C2.50992 0.0830078 0.083252 2.50967 0.083252 5.49967C0.083252 8.48967 2.50992 10.9163 5.49992 10.9163C8.48992 10.9163 10.9166 8.48967 10.9166 5.49967C10.9166 2.50967 8.48992 0.0830078 5.49992 0.0830078ZM6.04159 8.20801H4.95825V4.95801H6.04159V8.20801ZM6.04159 3.87467H4.95825V2.79134H6.04159V3.87467Z"
        fill="#C2C9D1"
      />
      <path
        d="M5.49992 0.0830078C2.50992 0.0830078 0.083252 2.50967 0.083252 5.49967C0.083252 8.48967 2.50992 10.9163 5.49992 10.9163C8.48992 10.9163 10.9166 8.48967 10.9166 5.49967C10.9166 2.50967 8.48992 0.0830078 5.49992 0.0830078ZM6.04159 8.20801H4.95825V4.95801H6.04159V8.20801ZM6.04159 3.87467H4.95825V2.79134H6.04159V3.87467Z"
        fill="black"
        fillOpacity="0.25"
      />
    </svg>
  );
};

export default InfoSVG;
