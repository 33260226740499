import Head from 'next/head';

interface Seo {
  title?: string;
  description?: string;
}
export default function SEO({ title, description }: Seo) {
  const seo: Seo = {
    title: title || 'Find a Mortgage Loan Officer | Keller Home Loans',
    description:
      description ||
      'We’re here to help you find the right mortgage solution to meet your goals. With competitive rates and fees, and a commitment to customer service, contact Keller Home Loans to get started.',
  };

  return (
    <Head>
      <title>{`${seo.title}`}</title>
      <meta name="description" content={seo.description} />
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
    </Head>
  );
}
