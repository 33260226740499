import React, { createContext, useState } from 'react';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormMachine } from 'src/context/FormMachineProvider';
import styles from './option-button.module.scss';

export const ButtonContext = createContext('default');

/* this componentes use a Compuned Component approach with the Svg components
   it shares its status with the Svg child components using context */
const OptionButton: React.FC<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > & {
    size?: 'small' | 'large' | 'medium';
    isSelected?: boolean;
    cypressTag?: string;
    onSelect?: () => void;
  }
> = ({
  children,
  className = '',
  isSelected,
  cypressTag,
  onSelect,
  size = 'small',
  ...prop
}) => {
  const [state, setState] = useState('default');
  const [, send] = useFormMachine();

  const handleOnClick = () => {
    if (isSelected) {
      send({ type: 'DESELECT_ANSWER' });
    } else {
      onSelect?.();
    }
  };

  return (
    <ButtonContext.Provider value={isSelected ? 'selected' : state}>
      <button
        onMouseEnter={() => setState('hover')}
        onMouseLeave={() => setState('default')}
        type="button"
        className={`${styles.optionButton} ${
          size === 'large' ? styles.large : ''
        } ${isSelected ? styles.selected : ''} ${className}`}
        data-cy={cypressTag || 'optionsQuestion'}
        onClick={handleOnClick}
        {...prop}
      >
        <FontAwesomeIcon
          className={styles.faCheckCircle}
          icon={faCheckCircle}
        />

        {children}
      </button>
    </ButtonContext.Provider>
  );
};

export default OptionButton;
