import * as Sentry from '@sentry/nextjs';
import { FormContext } from 'src/common/types';

export const HandleError = (
  type: 'exception' | 'message',
  message: string,
  extra?: any
) => {
  if (typeof window !== 'undefined') {
    const form = JSON.parse(
      window.sessionStorage
        ? window.sessionStorage.getItem('mainForm') || ''
        : '{}'
    );
    const context: FormContext = form ? { ...form.context } : null;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { address, userInfo, flowInfo, ...restContext } = context;

    Sentry.withScope(scope => {
      scope.setExtra('Flow Information', restContext);
      if (flowInfo.sessionID) {
        Sentry.setTag('session_id', flowInfo.sessionID);
      }
      if (extra) {
        scope.setExtra('Exception', extra);
      }
      if (type === 'message') {
        Sentry.captureMessage(message);
      } else {
        // prevent sentry from grouping errors wit same base url
        scope.setFingerprint(['{{ default }}', message]);
        Sentry.captureException(new Error(message));
      }
    });
  }
};
