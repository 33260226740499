import React, { ReactNode, useEffect, useRef, useState } from 'react';
import TooltipLeftArrow from 'src/components/commons/svg/tooltipLeftArrow';
import styles from './basic-tool-tip.module.scss';

interface BasicTooltipProps {
  icon: ReactNode;
  children: ReactNode;
}
interface Position {
  top: number;
  left: number;
}
const BasicTooltip = ({ icon, children }: BasicTooltipProps) => {
  const [active, setActive] = useState(false);
  const [position, setPosition] = useState<Position>({ top: 0, left: 0 });
  const tooltipRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (tooltipRef.current) {
      const rect = tooltipRef.current.getBoundingClientRect();
      const { top, left } = rect;
      setPosition({ top, left });
    }
  }, [active]);

  const tooltipPosition = {
    top: `${position.top - 5}px`,
    left: `${position.left + 35}px`,
  };

  return (
    <div
      className={styles.wrapper}
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
    >
      <div ref={tooltipRef}>{icon}</div>
      {active ? (
        <div className={styles.tooltip} style={tooltipPosition}>
          {children}
          <TooltipLeftArrow classes={styles.tooltipArrow} />
        </div>
      ) : null}
    </div>
  );
};

export default BasicTooltip;
