import { FormContext, ParsedQueryParams } from 'src/common/types';
import { checkIfThereAreParams, getQueryParamsData } from 'src/utils';
import { getOrUpdateSessionId } from 'src/utils/fetchActions';
import { assign, DoneInvokeEvent } from 'xstate';

export const initializingNode = {
  initial: 'activateModal',
  on: {
    // if user clicks start flow button before initials fetching is done
    START_FLOW: { actions: { type: 'changeModal', modal: 'fullPageLoader' } },
  },
  states: {
    activateModal: {
      always: [
        {
          target: 'initSession',
          cond: 'queryParamsAvailable',
          actions: { type: 'changeModal', modal: 'loading' },
        },
        {
          target: 'initSession',
          actions: { type: 'changeModal', modal: '' },
        },
      ],
    },
    initSession: {
      invoke: {
        id: 'initSessionId',
        src: (context: FormContext) => getOrUpdateSessionId(context, null),
        onDone: {
          target: 'getQueryParamsData',
          actions: 'setSessionData',
        },
        onError: {
          target: '#idle',
          actions: { type: 'changeModal', modal: 'error' },
        },
      },
    },
    getQueryParamsData: {
      on: {
        NO_QUERY_PARAMS_DATA: {
          target: '#idle',
          actions: { type: 'changeModal', modal: '' },
        },
        START_FLOW: { target: '#steps.hist' },
      },
      invoke: {
        id: 'getQueryParamsData',
        src: (context: FormContext) => (cb: any) => {
          const [saveParams, parsed] = checkIfThereAreParams();
          if (saveParams) {
            return getQueryParamsData(
              context.flowInfo.authToken,
              parsed as ParsedQueryParams
            );
          }
          cb('NO_QUERY_PARAMS_DATA');
          if (context.activeModal === 'fullPageLoader') {
            cb('START_FLOW');
          }
          return undefined;
        },
        onDone: [
          {
            cond: 'queryParamsBuyFlow',
            actions: ['setQueryParamsData'],
            target: '#innerSteps.homeType',
          },
          {
            cond: 'queryParamsRefiFlow',
            actions: ['setQueryParamsData'],
            target: '#innerSteps.whatIsYourCurrentAddress',
          },
          {
            target: '#innerSteps.buyOrRefinance',
            actions: ['setQueryParamsData'],
          },
        ],
        onError: { target: 'initSessionFinal' },
      },
    },
    initSessionFinal: {
      type: 'final' as 'final',
    },
  },
  onDone: [{ target: '#innerSteps.buyOrRefinance' }],
};

export const initializingActions = {
  setSessionData: assign((context: FormContext, event: any) => {
    return {
      ...context,
      flowInfo: {
        ...context.flowInfo,
        authToken: event.data.authToken,
        sessionID: event.data.sessionID,
      },
      utm: {
        ...context.utm,
        term: typeof window !== 'undefined' ? window.location.href : '',
      },
    };
  }),
  setQueryParamsData: assign(
    (context: FormContext, event: DoneInvokeEvent<any>) => {
      // if we have a buy or refi query param, we want to handle the same way that the borrowers dropdown selection
      const origin = event.data.buyOrRefinance
        ? `${event.data.buyOrRefinance}Dropdown`
        : event.data.origin;
      const hasAgentOrTeam = event.data.getAgentInfo;
      return {
        ...context,
        answers: {
          ...context.answers,
          getAgentInfo: event.data.getAgentInfo,
          buyOrRefinance: hasAgentOrTeam ? event.data.buyOrRefinance : '',
        },
        flowInfo: {
          ...context.flowInfo,
          origin: hasAgentOrTeam ? origin : event.data.origin,
          campaignID: event.data.campaignID,
          urlHasCampaignId: event.data.urlHasCampaignId,
          isBuyerAgent: event.data.isBuyerAgent,
          isSellerAgent: event.data.isSellerAgent,
          loanFromPreApp: event.data.loanFromPreApp,
          aba: event.data.aba,
        },
        agent: event.data.agent,
        team: event.data.team,
        marketCenter: event.data.marketCenter,
        utm: event.data.utm,
      };
    }
  ),
};
export const initializingGuards = {
  queryParamsAvailable: () => {
    const [available] = checkIfThereAreParams();
    return available;
  },
  queryParamsBuyFlow: (_context: FormContext, event: any) =>
    event.data.buyOrRefinance === 'buy',
  queryParamsRefiFlow: (_context: FormContext, event: any) =>
    event.data.buyOrRefinance === 'refinance',
};
