import { useEffect } from 'react';
// import { config } from '@fortawesome/fontawesome-svg-core';
// import { inspect } from '@xstate/inspect';
import { ApolloProvider } from '@apollo/client';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';
import ModalsHandler from 'components/commons/ModalsHandler/ModalsHandler';
import RouteHandler from 'components/commons/RouteHandler/RouteHandler';
import SEO from 'components/commons/Seo/SEO';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import Script from 'next/script';
import Modal from 'react-modal';
import { client } from 'src/apollo/client';
import { MainFormProvider } from 'src/context/FormMachineProvider';
import { theme } from 'src/styles/theme';
import { pageview } from 'src/utils/gtag';
import { gaTagScript, globalThisPolyfill } from 'src/utils/scripts';
import { ThemeProvider } from 'styled-components';
import '../src/styles/async-select.scss';
import '../src/styles/globals.scss';
import '../src/styles/modal.scss';
import '../src/styles/react-select.scss';
import '../src/styles/transitions.scss';

// const inspectEnabled = process.env.NODE_ENV === 'development';
// config.autoAddCss = false;

// if (typeof window !== 'undefined' && inspectEnabled) {
//   inspect({
//     iframe: false,
//   });
// }
Modal.setAppElement('#__next');

const growthbook = new GrowthBook();

function App({ Component, pageProps, err }: AppProps & { err: any }) {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      pageview(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    fetch(process.env.NEXT_PUBLIC_GROWTHBOOK_ENDPOINT || '')
      .then(res => res.json())
      .then(json => {
        growthbook.setFeatures(json.features);
      });
  }, []);

  return (
    <>
      <MainFormProvider>
        <SEO />
        <GrowthBookProvider growthbook={growthbook}>
          <ApolloProvider client={client}>
            <ThemeProvider theme={theme}>
              <RouteHandler isNotFound={Component.name === 'NotFound'}>
                <Component {...pageProps} err={err} />
                <ModalsHandler />
              </RouteHandler>
            </ThemeProvider>
          </ApolloProvider>
        </GrowthBookProvider>
      </MainFormProvider>

      <Script
        strategy="beforeInteractive"
        dangerouslySetInnerHTML={{ __html: globalThisPolyfill }}
      />

      {/* Global Site Tag (gtag.js) - Google Analytics */}
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA_ID}`}
      />
      <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{ __html: gaTagScript }}
      />
      {/* End Google Analytic */}

      {/* Google Maps */}
      <Script
        type="text/javascript"
        strategy="beforeInteractive"
        src={`https://maps.googleapis.com/maps/api/js?language=en&libraries=places&key=${process.env.NEXT_PUBLIC_GOOGLE_MAP_KEY}`}
      />
      {/* End Google Maps */}
    </>
  );
}

export default App;
