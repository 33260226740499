import { FormContext, homeTypeEvents } from 'src/common/types';
import { ActionFunctionMap, assign } from 'xstate';

export const homeTypeNode = {
  initial: 'idle',
  states: {
    idle: {
      on: {
        SET_HOME_TYPE: {
          target: 'ready',
          actions: { type: 'setHomeType' },
        },
      },
    },
    ready: {
      on: {
        SET_HOME_TYPE: {
          target: 'ready',
          actions: { type: 'setHomeType' },
        },
        DESELECT_ANSWER: {
          target: 'idle',
          actions: 'clearHomeType',
        },
        NEXT: {
          target: '#innerSteps.propertyUse.hist',
        },
      },
    },
    hist: {
      type: 'history' as 'history',
    },
  },
  on: {
    BACK: [
      {
        target: '#idle',
        cond: 'buyDropdown',
      },
      {
        target: '#steps.innerSteps.buyOrRefinance.hist',
      },
    ],
  },
};

export const homeTypeActions: ActionFunctionMap<FormContext, homeTypeEvents> = {
  setHomeType: assign((context: FormContext, event) => ({
    ...context,
    answers: {
      ...context.answers,
      homeType: event.homeType,
    },
  })),
  clearHomeType: assign((context: FormContext) => ({
    ...context,
    answers: {
      ...context.answers,
      homeType: '',
    },
  })),
};
