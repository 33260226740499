import React from 'react';
import styles from './loader.module.scss';

interface LoaderProps {
  size?: 'small' | 'default';
  variant?: 'grey' | 'default';
}

const Loader = ({ size = 'default', variant = 'default' }: LoaderProps) => {
  return (
    <div
      className={`${styles.loader} ${size === 'small' ? styles.small : ''} ${
        variant === 'grey' ? styles.grey : ''
      }`}
    >
      Loading...
    </div>
  );
};

export default Loader;
