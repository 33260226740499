import { FormContext } from 'src/common/types';
import { ActionFunctionMap, assign } from 'xstate';

export const doesThisLookRightNode = {
  initial: 'ready',
  states: {
    idle: {
      on: { SET_ADDRESS: { actions: 'setAddress', target: 'ready' } },
    },
    ready: {
      on: {
        CLEAR_ADDRESS: { target: 'idle', actions: 'clearAddress' },
        NEXT: { target: '#innerSteps.getUserInfo' },
      },
    },
    hist: {
      type: 'history' as 'history',
    },
  },
  on: {
    SET_UNIT: { actions: 'setUnit' },
    SET_DWELLING_DETAILS: { actions: 'setDwellingDetails' },
    SET_ASKING_PRICE: { actions: 'setAskingPrice' },
    SET_LIENS: { actions: 'setLiens' },
    BACK: [
      { target: 'whatIsYourCurrentAddress.hist', cond: 'refinanceDropdown' },
      { target: '#innerSteps.buyOrRefinance.hist' },
    ],
  },
};

export const doesThisLookRightActions: ActionFunctionMap<FormContext, any> = {
  setDwellingDetails: assign({
    dwellingDetails: (context, event) => ({
      ...context.dwellingDetails,
      ...event.details,
    }),
  }),
  setAskingPrice: assign({
    answers: (context, event) => ({
      ...context.answers,
      askingPrice: event.askingPrice,
    }),
  }),
  setLiens: assign({
    liens: (ctx, event) => ({ ...ctx.liens, ...event.liens }),
  }),
};
