import {
  SaveProgressContext,
  SaveProgressSchema,
  SaveProgressTransitions,
} from 'src/common/types';
import {
  sendParent,
  assign,
  MachineConfig,
  MachineOptions,
  createMachine,
} from 'xstate';
import { saveProgress } from '../utils/fetchActions';

const saveProgressMachineConfiguration: MachineConfig<
  SaveProgressContext,
  SaveProgressSchema,
  SaveProgressTransitions
> = {
  id: 'saveProgress',
  initial: 'idle',
  context: { email: undefined, link: undefined, error: undefined },
  states: {
    idle: {
      entry: sendParent({
        type: 'CHANGE_MODAL',
        modal: 'saveProgress',
      }),
      on: {
        SAVE_PROGRESS: { target: 'loading', actions: 'saveData' },
        CLOSE: { target: 'finished', actions: 'close' },
      },
    },
    loading: {
      invoke: {
        id: 'saveProgress',
        src: context => saveProgress(context.email!, context.link!),
        onDone: {
          target: 'success',
        },
        onError: {
          target: 'failure',
          actions: assign({ error: (_context, event) => event.data }),
        },
      },
    },
    failure: {},
    success: {
      entry: sendParent({
        type: 'CHANGE_MODAL',
        modal: 'saveProgressConfirmation',
      }),
      on: {
        CLOSE: {
          target: 'finished',
          actions: 'close',
        },
      },
    },
    finished: {
      type: 'final',
    },
  },
};

const saveProgressMachineOptions: Partial<
  MachineOptions<SaveProgressContext, SaveProgressTransitions>
> = {
  actions: {
    saveData: assign((context, event) => {
      if (event?.type !== 'SAVE_PROGRESS') return { ...context };
      return {
        email: event.email,
        link: event.link,
      };
    }),
    close: sendParent({
      type: 'CHANGE_MODAL',
      modal: '',
    }),
  },
};

const saveProgressMachine = createMachine(
  saveProgressMachineConfiguration,
  saveProgressMachineOptions
);

export default saveProgressMachine;
