export const noAgentLang = {
  title: 'Save thousands with the ZeroPlus loan program',
  paragraph:
    'To qualify, you must work with a Keller Williams agent, a Keller Williams office, or purchase a Keller Williams listing',
  zeroplusDescription:
    'ZeroPlus is a Keller Mortgage loan program exclusive to Keller Williams transactions.',
  zeroplusSmall:
    '(Credit applied towards 3rd party costs. $150k+ loan amount required)',
  options: [
    {
      lang: 'Find a Keller Williams agent',
      value: 'has KW agent',
      gaEvent: 'noKwAgentModalOption1',
      action: 'AGENT',
    },
    {
      lang: "I'm buying a Keller Williams listing",
      value: 'buying from KW agent',
      gaEvent: 'noKwAgentModalOption3',
      action: 'LISTING',
    },
    {
      lang: 'Continue without ZeroPlus discount',
      value: 'skipped KW agent',
      gaEvent: 'noKwAgentModalOption4',
      action: 'SKIP',
    },
  ],
};
