export default {
  title: 'You’re in good hands',
  eligibilityText:
    'If you are buying a home with a Keller Williams agent or buying a Keller Williams listing, you are eligible for the ZeroPlus loan program and all the benefits that come with it.',
  advantages: {
    lenderFees: 'Zero lender fees',
    credit: 'Plus $1,000 credit at closing',
    discount: 'Plus a discounted rate',
  },
  consumerAccess: 'NMLS #140234 Consumer Access',
};
