/* eslint-disable react/button-has-type */
import React from 'react';
import Link from 'next/link';
import styles from './primary-button.module.scss';

interface PrimaryButtonProps {
  disabled?: boolean;
  children: string;
  color?: string;
  handleOnClick?: (e: React.MouseEvent<HTMLElement>) => void;
  linkTo?: string;
  specialWidth?: string;
  type?: 'button' | 'submit' | 'reset';
  testID?: string;
  cypressTag?: string;
  customClass?: string;
  look?: 'applyNow';
  id?: string;
}

const PrimaryButton = ({
  disabled,
  children,
  color = 'primary',
  handleOnClick,
  linkTo,
  specialWidth,
  customClass,
  look,
  type,
  testID,
  cypressTag,
  id,
}: PrimaryButtonProps) => {
  const dynamicClasses = `${styles[color]} ${
    specialWidth ? styles[specialWidth] : ''
  } ${disabled ? styles.disabled : ''} ${customClass}`;

  const specialLink = () => {
    if (linkTo && (linkTo.includes('mailto:') || linkTo.includes('tel:'))) {
      return true;
    }
    return false;
  };

  if (linkTo && !specialLink()) {
    return (
      <Link
        href={linkTo}
        className={`${styles.pillButton} ${dynamicClasses} ${
          look === 'applyNow' ? styles.applyNowButton : ''
        }`}
      >
        <button
          disabled={disabled}
          onClick={handleOnClick}
          type={type}
          data-testid={testID}
          data-cy={cypressTag}
          id={id}
        >
          <span>{children}</span>
        </button>
      </Link>
    );
  }

  if (specialLink()) {
    return (
      <a className={`${styles.pillButton} ${dynamicClasses}`} href={linkTo}>
        <button
          disabled={disabled}
          onClick={handleOnClick}
          type={type}
          data-testid={testID}
          data-cy={cypressTag}
        >
          <span>{children}</span>
        </button>
      </a>
    );
  }

  return (
    <button
      className={`${styles.pillButton} ${dynamicClasses} ${
        look === 'applyNow' ? styles.applyNowButton : ''
      }`}
      disabled={disabled}
      onClick={handleOnClick}
      type={type}
      data-testid={testID}
      data-cy={cypressTag}
      id={id}
    >
      <span>{children}</span>
    </button>
  );
};

export default PrimaryButton;
