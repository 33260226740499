import { FormContext } from 'src/common/types';
import {
  getOrUpdateSessionId,
  postPreApproval,
  postReferral,
} from 'src/utils/fetchActions';
import { ActionFunctionMap, assign, send } from 'xstate';

export const submitDataNode = {
  initial: 'idle',
  entry: 'sendSubmitForm',
  id: 'submitData',
  states: {
    idle: {
      on: {
        SUBMIT_FORM: [
          {
            target: 'postReferral',
            actions: [
              'setAcceptTermsOfService',
              { type: 'changeModal', modal: 'loading' },
            ],
            cond: 'notWorkingWithAnAgent',
          },
          {
            target: 'postPreapproval',
            actions: [
              'setAcceptTermsOfService',
              { type: 'changeModal', modal: 'loading' },
            ],
          },
        ],
      },
    },
    postReferral: {
      invoke: {
        id: 'postReferral',
        src: (context: FormContext) =>
          postReferral(context.flowInfo.sessionID, context.flowInfo.authToken),
        onDone: {
          target: 'postPreapproval',
        },
        onError: {
          target: 'postPreapproval',
        },
      },
    },
    postPreapproval: {
      invoke: {
        id: 'postPreapproval',
        src: (context: FormContext) => postPreApproval(context),
        onDone: {
          target: 'updateFinalSession',
          actions: 'setSubmitted',
        },
        onError: {
          target: 'postPreapprovalFailure',
          actions: [{ type: 'changeModal', modal: 'flowCompleted' }],
        },
      },
    },
    updateFinalSession: {
      invoke: {
        id: 'updateFinalSession',
        src: (context: FormContext) => getOrUpdateSessionId(context, null),
        onDone: {
          target: 'postPreapprovalSuccess',
          actions: [{ type: 'changeModal', modal: '' }],
        },
        onError: {
          target: 'postPreapprovalFailure',
        },
      },
    },
    postPreapprovalSuccess: {
      type: 'final' as 'final',
    },
    postPreapprovalFailure: {
      type: 'final' as 'final',
    },
  },
  onDone: {
    target: '#finished',
  },
};

export const submitDataActions: ActionFunctionMap<FormContext, any> = {
  setSubmitted: assign((context, event) => ({
    ...context,
    flowInfo: {
      ...context.flowInfo,
      isLeadSubmitted: true,
    },
    redirectUrl: event.data?.data?.attributes?.redirect_url,
  })),
  setAcceptTermsOfService: assign({
    termsOfService: _ctx => ({
      isAccepted: true,
      dateAccepted: Date(),
    }),
  }),
  sendSubmitForm: send('SUBMIT_FORM'),
};

export const submitDataGuards = {
  notWorkingWithAnAgent: (context: FormContext) =>
    context.answers.getAgentInfo !== 'has KW agent',
};
