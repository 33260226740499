import { FormContext, propertyUseEvents } from 'src/common/types';
import { ActionFunctionMap, assign } from 'xstate';

export const propertyUseNode = {
  initial: 'idle',
  on: {
    BACK: '#innerSteps.homeType.hist',
  },
  states: {
    idle: {
      on: {
        SET_PROPERTY_USE: {
          target: 'ready',
          actions: 'setPropertyUse',
        },
      },
    },
    ready: {
      on: {
        SET_PROPERTY_USE: {
          target: 'ready',
          actions: 'setPropertyUse',
        },
        DESELECT_ANSWER: {
          target: 'idle',
          actions: 'clearPropertyUse',
        },
        NEXT: '#innerSteps.propertyAddress.hist',
      },
    },
    hist: {
      type: 'history' as 'history',
    },
  },
};

export const propertyUseActions: ActionFunctionMap<
  FormContext,
  propertyUseEvents
> = {
  clearPropertyUse: assign({
    answers: context => ({ ...context.answers, propertyUse: '' }),
  }),
  setPropertyUse: assign({
    answers: (ctx, event) => ({ ...ctx.answers, propertyUse: event.value }),
  }),
};
