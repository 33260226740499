import { GaEvent, GaPage } from 'src/common/types';
import gaActions from './gaActions';

export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_ID;
declare global {
  interface Window {
    gtag: any;
    showSplash: string;
  }
}

export const pageview = (url: string) => {
  if (typeof window !== 'undefined') {
    window.gtag('config', GA_TRACKING_ID, {
      page_path: url,
    });
  }
};

export const event = ({
  action,
  category,
  label = null,
}: {
  action: string;
  category: GaPage;
  label: string | null;
}) => {
  if (typeof window !== 'undefined') {
    window.gtag?.('event', action, {
      event_category: category,
      event_label: label,
    });
  }
};

const sendGaEvent = (eventType: GaEvent, category: GaPage, label = null) => {
  const pageActions = gaActions[category];
  event({
    category,
    label,
    action: pageActions[eventType as keyof typeof pageActions],
  });
};

export default sendGaEvent;
