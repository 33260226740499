import Modal from 'components/commons/Modal/Modal';
import SearchDropDown from 'components/mortgage-application/SearchDropDown/SearchDropDown';
import React, { useEffect, useState } from 'react';
import { useFormMachine } from 'src/context/FormMachineProvider';
import agentSearchPrompt from 'src/data/lang/mortgageApplication/agent-search-modal.lang';
import sendGaEvent from 'src/utils/gtag';
import styles from './agent-search-modal.module.scss';

const AgentSearchModal = () => {
  const [current, send] = useFormMachine();
  useEffect(() => {
    sendGaEvent('agentSearchModal', 'mortgageRate');
  }, []);
  const [searchTerm, setSearchTerm] = useState('');

  const handleClose = () => {
    sendGaEvent('aagentSearchModalClose', 'mortgageRate');
    if (current.context.lastState === 'innerSteps') {
      send('ClOSE_MODAL_AND_GO_LAST_STEP');
    } else {
      send('CANCEL_HAS_AGENT');
    }
  };
  const handleModalSubmit = () => {
    send({ type: 'SEARCH', searchTerm });
    sendGaEvent('agentSearchModalSearch', 'mortgageRate');
  };
  return (
    <Modal isOpen className={styles.dialog} onRequestClose={handleClose}>
      <h1 className={styles.title}>{agentSearchPrompt}</h1>

      <div className={styles.autocompleteWrapper}>
        <SearchDropDown
          onChange={e => setSearchTerm(e.target.value)}
          onSubmit={handleModalSubmit}
        />
      </div>
    </Modal>
  );
};

export default AgentSearchModal;
