export default {
  nationalLoanName:
    'Conforming30YearFixedLTVLessThanEqualTo80FICOGreaterThanEqualTo740',
  lifetimeSavings: 'Your lifetime savings',
  mortgageAmountLabel: 'Mortgage amount',
  mortgagePeriodLabel: 'Mortgage period',
  mortgagePeriodOptions: [
    { label: '30 Year', value: 'ThirtyYear' },
    { label: '15 year', value: 'FifteenYear' },
  ],
  mortgagePeriodFinePrint1: 'Total cost of loan:',
  mortgagePeriodFinePrint2: 'Considers 20% down payment',
  nationalInterestLabel: 'Interest rate',
  nationalPaymentLabel: 'Monthly payment',
  zppInterestLabel: 'Your interest rate',
  zppPaymentLabel: 'Your monthly payment',
  zeroPlusCopy: [
    'Zero lender fees',
    'Plus $1,000 credit at closing',
    'Plus a discounted rate',
  ],
  zeroPlusBrandFinePrint:
    '(Credit applied towards 3rd party costs. $150k+ loan amount required)',
  disclaimer: '*Disclaimer',
};
