import queryString from 'query-string';
import { FormContext, FormEvent } from 'src/common/types';
import { mainMachine } from 'src/machines/mainMachine';
import { State } from 'xstate';

/**
 * Gets initial machine state from two possible sources: sessionStorage or url "reference" query param.
 * "reference" query param has priority over session storage.
 */
const getPersistedState = (): State<FormContext, FormEvent, any> => {
  if (typeof window !== 'undefined') {
    const parsed = queryString.parse(window.location.search);
    if (!parsed.reference) {
      try {
        return JSON.parse(sessionStorage.getItem('mainForm')!);
      } catch {
        return mainMachine.initialState;
      }
    }

    try {
      return JSON.parse(window.atob(parsed.reference as string));
    } catch {
      return mainMachine.initialState;
    }
  }
  return mainMachine.initialState;
};

export { getPersistedState };
