import React, { useEffect } from 'react';
import Modal from 'components/commons/Modal/Modal';
import { useFormMachine } from 'src/context/FormMachineProvider';

import sendGaEvent from 'src/utils/gtag';
import agentNotFoundModalLang from 'src/data/lang/mortgageApplication/agent-not-found-modal.lang';
import SecondaryButton from 'components/commons/SecondaryButton/SecondaryButton';
import PrimaryButton from 'components/commons/PrimaryButton/PrimaryButton';
import styles from './agent-not-found-modal.module.scss';

const AgentNotFound = () => {
  const [, send] = useFormMachine();

  useEffect(() => {
    sendGaEvent('agentNotFoundModal', 'mortgageRate');
  }, []);

  const { searchAgain, mainText, subText, continueTo } = agentNotFoundModalLang;

  const handleSearchAgain = () => {
    send('SEARCH_AGAIN_AGENT_NOT_FOUND');
    sendGaEvent('agentNotFoundModalSearchAgain', 'mortgageRate');
  };

  const handleContinue = () => {
    send('CONTINUE_AGENT_NOT_FOUND');
    sendGaEvent('agentNotFoundModalContinue', 'mortgageRate');
  };

  const handleClose = () => {
    send('CANCEL_HAS_AGENT');
    sendGaEvent('agentNotFoundModalClose', 'mortgageRate');
  };

  return (
    <Modal
      onRequestClose={handleClose}
      isOpen
      className={styles.dialog}
      //   testID="agentNotFoundModal"
    >
      <p className={styles.mainText}>{mainText}</p>
      <p className={styles.subText}>{subText}</p>
      <div className={styles.actionContainer}>
        <SecondaryButton handleOnClick={handleSearchAgain}>
          {searchAgain}
        </SecondaryButton>
        <PrimaryButton
          cypressTag="noAgentContinue"
          handleOnClick={handleContinue}
        >
          {continueTo}
        </PrimaryButton>
      </div>
    </Modal>
  );
};

export default AgentNotFound;
