import Modal from 'components/commons/Modal/Modal';
import React from 'react';
import { useFormMachine } from 'src/context/FormMachineProvider';
import Loader from 'components/commons/Loader/Loader';
import styles from './loading-modal.module.scss';

const LoadingModal = () => {
  const [state] = useFormMachine();
  return (
    <Modal isOpen className={styles.loadingModal}>
      <Loader />
      <p>
        {state.context.isLoadingToSplash
          ? 'Please wait while submit your application details'
          : 'One moment please while we transfer you'}
      </p>
    </Modal>
  );
};

export default LoadingModal;
