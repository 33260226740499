import React, { createContext, useContext, useEffect } from 'react';
import { useActor, useInterpret } from '@xstate/react';
import { mainMachine } from 'src/machines/mainMachine';
import { ActorRefFrom, Sender, State } from 'xstate';
import { getPersistedState } from 'src/utils/getPersistedState';
import { checkIfThereAreParams } from 'src/utils';
import { FormContext, FormEvent } from 'src/common/types';

export const MainFormContext = createContext(
  {} as { mainFormService: ActorRefFrom<typeof mainMachine> }
);

const persistedState = getPersistedState();

export const MainFormProvider: React.FC = ({ children }) => {
  const path = typeof window !== 'undefined' ? window.location.pathname : '';
  const [areParamsAvailable] = checkIfThereAreParams();

  const mainFormService = useInterpret(
    mainMachine,
    {
      devTools: true,
      // query params have higher priority so we leave the machine handle that if exists
      state: areParamsAvailable ? mainMachine.initialState : persistedState,
    },
    // this is a listener that persist the state in the sessionStorage
    state => {
      const { context, _event, value } = state;
      if (state.changed) {
        const activeModal = /agent|saveProgress/i.test(context.activeModal)
          ? ''
          : context.activeModal;
        sessionStorage.setItem(
          'mainForm',
          JSON.stringify({
            ...mainFormService.state,
            context: { ...context, activeModal },
            actions: [],
            children: {},
            _event,
            value,
          })
        );
      }
    }
  );

  useEffect(() => {
    if (
      path !== mainFormService.state.context.activePage &&
      (path.includes('mortgage-application') ||
        path.includes('checklist') ||
        path.includes('quotes'))
    ) {
      mainFormService.send({ type: 'UPDATE_SESSION' });
    }
  }, [mainFormService, path]);

  return (
    <MainFormContext.Provider value={{ mainFormService }}>
      {children}
    </MainFormContext.Provider>
  );
};

export const useFormMachine = (): [
  State<
    FormContext,
    FormEvent,
    any,
    {
      value: any;
      context: FormContext;
    }
  >,
  Sender<FormEvent>
] => {
  const formServices = useContext(MainFormContext);
  const [state] = useActor(formServices.mainFormService);

  if (formServices) {
    return [state, formServices.mainFormService.send];
  }
  throw new Error(
    'Form Machine context is only accesible for components inside a MachineProvider'
  );
};
