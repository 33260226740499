/* eslint-disable import/named */
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useActor } from '@xstate/react';
import Modal from 'components/commons/Modal/Modal';
import PrimaryButton from 'components/commons/PrimaryButton/PrimaryButton';
import ZeroPlusByKMLogo from 'components/commons/svg/ZeroPlusByKm';
import OptionButton from 'components/mortgage-application/OptionButton/OptionButton';
import React, { useEffect } from 'react';
import { GaEvent } from 'src/common/types';
import { useFormMachine } from 'src/context/FormMachineProvider';
import advantagesLang from 'src/data/lang/good-hands.lang';
import { noAgentLang } from 'src/data/lang/mortgageApplication/no-agent-modal.lang';
import sendGAEvent from 'src/utils/gtag';
import styles from './no-kw-agent-modal.module.scss';

const { advantages } = advantagesLang;
const {
  options,
  paragraph,
  title,
  zeroplusDescription,
  zeroplusSmall,
} = noAgentLang;
const NoAgentModal = () => {
  const [current, send] = useFormMachine();
  const [getAgentInfoMachine] = useActor(current.children.getAgentInfo);
  const [noAgent, sendNoAgent] = useActor(getAgentInfoMachine.children.noAgent);

  const selectedOption = (noAgent as any)?.context.answer;

  useEffect(() => {
    sendGAEvent('noKwAgentModal', 'mortgageRate');
  }, []);

  const handleSelection = (action: string, value: string) => {
    const { gaEvent } = options.filter(item => item.action === action)[0];
    sendGAEvent(gaEvent as GaEvent, 'mortgageRate');
    sendNoAgent({ type: action, answer: value });
  };

  const handleClose = () => {
    sendGAEvent('noKwAgentModalClose', 'mortgageRate');
    if (current.context.lastState === 'innerSteps') {
      send('ClOSE_MODAL_AND_GO_LAST_STEP');
    } else {
      send('CANCEL_NO_AGENT');
    }
  };

  const handleContinue = () => {
    sendGAEvent('noKwAgentModalContinue', 'mortgageRate');
    sendNoAgent('CONTINUE');
  };

  return (
    <Modal
      isOpen
      onRequestClose={handleClose}
      className={styles.dialog}
      testId="noKWAgentModal"
      contentLabel="No keller williams agent modal"
    >
      <div className={styles.main}>
        <div className={styles.selectionContainer}>
          <p className={styles.text}>{title}</p>
          <p className={styles.subText}>{paragraph} </p>
          <div className={styles.buttonsContainer}>
            {options.map(option => {
              return (
                <OptionButton
                  isSelected={selectedOption === option.value}
                  key={`option-button-${option.value}`}
                  lang={option.lang}
                  size="small"
                  onClick={() => handleSelection(option.action, option.value)}
                  cypressTag="optionsQuestion"
                >
                  {option.lang}
                </OptionButton>
              );
            })}
          </div>

          <div
            className={`${styles.actionButtons} ${
              selectedOption ? styles.highlightedButton : ''
            }`}
          >
            <PrimaryButton
              disabled={!selectedOption}
              handleOnClick={handleContinue}
              type="button"
              testID="noKWAgentModalContinue"
              cypressTag="continue"
            >
              Continue
            </PrimaryButton>
          </div>
        </div>
        <div className={styles.zeroPlus}>
          <ZeroPlusByKMLogo />
          <p>{zeroplusDescription}</p>
          <ul className={styles.advantageList}>
            {Object.keys(advantages).map(adv => (
              <li className={styles.advantageText} key={adv}>
                <FontAwesomeIcon
                  className={styles.faCheckCircle}
                  icon={faCheckCircle}
                />
                {advantages[adv as keyof typeof advantages]}
              </li>
            ))}
          </ul>
          <p className={styles.finePrint}>{zeroplusSmall}</p>
        </div>
      </div>
    </Modal>
  );
};

export default NoAgentModal;
