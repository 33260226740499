import { HandleError } from 'src/utils/sentry';

export async function client(
  endpoint: string,
  {
    data = null,
    token = null,
    headers: customHeaders,
    method = null,
    signal,
    ...customConfig
  }: {
    data?: any;
    token?: string | null;
    headers?: any;
    method?: 'PUT' | 'DELETE' | null;
    signal?: AbortSignal;
  } = {}
) {
  const defaultMethod = data ? 'POST' : 'GET';

  const config = {
    method: method || defaultMethod,
    body: data ? JSON.stringify(data) : undefined,
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      ...(data && { 'Content-Type': 'application/json' }),
      ...customHeaders,
    },
    signal,
    ...customConfig,
  };
  return fetch(endpoint, config)
    .then(async response => {
      const responseData = await response.json();

      if (response.ok) {
        return responseData;
      }
      return Promise.reject(responseData);
    })
    .catch(error => {
      HandleError('exception', ` ${endpoint} Wrong response data`, error);
      return null;
    });
}
