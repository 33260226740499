import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { useFormMachine } from 'src/context/FormMachineProvider';
import sendGaEvent from 'src/utils/gtag';

const RouteHandler: React.FC<{ isNotFound?: boolean }> = ({
  children,
  isNotFound,
}) => {
  const router = useRouter();

  const [state] = useFormMachine();

  useEffect(() => {
    if (isNotFound) {
      return;
    }

    switch (true) {
      case state.matches('steps.getAgentInfo'):
        if (router.pathname === '/mortgage-application/agent-info') {
          return;
        }

        router.push('/mortgage-application/get-agent-info');
        sendGaEvent('getAgentInfoPage', 'mortgageRate');
        break;
      case state.matches('steps.innerSteps.buyOrRefinance'):
        if (router.pathname === '/mortgage-application/buy-or-refinance') {
          return;
        }
        router.push('/mortgage-application/buy-or-refinance');
        sendGaEvent('buyOrRefinancePage', 'mortgageRate');
        break;
      case state.matches('steps.innerSteps.homeType'):
        if (router.pathname === '/mortgage-application/home-type') {
          return;
        }
        router.push('/mortgage-application/home-type');
        sendGaEvent('homeTypePage', 'mortgageRate');
        break;
      case state.matches('steps.innerSteps.propertyUse'):
        if (router.pathname === '/mortgage-application/property-use') {
          return;
        }
        router.push('/mortgage-application/property-use');
        sendGaEvent('propertyUsePage', 'mortgageRate');
        break;
      case state.matches('steps.innerSteps.propertyAddress'):
        if (router.pathname === '/mortgage-application/property-address') {
          return;
        }
        router.push('/mortgage-application/property-address');
        sendGaEvent('propertyAddressPage', 'mortgageRate');
        break;
      case state.matches('steps.innerSteps.purchaseDate'):
        if (router.pathname === '/mortgage-application/purchase-date') {
          return;
        }

        router.push('/mortgage-application/purchase-date');
        sendGaEvent('purchaseDatePage', 'mortgageRate');
        break;
      case state.matches('steps.innerSteps.askingPrice'):
        if (router.pathname === '/mortgage-application/asking-price') {
          return;
        }
        router.push('/mortgage-application/asking-price');
        sendGaEvent('askingPricePage', 'mortgageRate');
        break;
      case state.matches('steps.innerSteps.getUserInfo'):
        if (router.asPath === '/mortgage-application/get-user-info') {
          return;
        }

        router.push('/mortgage-application/get-user-info');
        sendGaEvent('userInfoPage', 'mortgageRate');
        break;
      case state.matches('steps.innerSteps.disclaimer') &&
        state.context.flowInfo.aba === 'on':
        if (router.asPath === '/mortgage-application/disclaimer') {
          return;
        }
        router.push('/mortgage-application/disclaimer');
        sendGaEvent('disclaimerPage', 'mortgageRate');
        break;
      case state.matches('steps.innerSteps.doesThisLookRight'):
        if (router.asPath === '/mortgage-application/does-this-look-right') {
          return;
        }
        router.push('/mortgage-application/does-this-look-right');
        sendGaEvent('doesThisLookRightPage', 'mortgageRate');
        break;
      case state.matches('steps.innerSteps.whatIsYourCurrentAddress'):
        if (
          router.asPath ===
          '/mortgage-application/what-is-your-current-street-address'
        ) {
          return;
        }
        router.push(
          '/mortgage-application/what-is-your-current-street-address'
        );
        sendGaEvent('propertyAddressPage', 'mortgageRate');
        break;
      case state.matches('steps.innerSteps.quotes.quotes'):
        router.push('/mortgage-application/quotes');
        sendGaEvent('propertyAddressPage', 'mortgageRate');
        break;
      case state.matches('steps.innerSteps.quotes.checklistQuotes'):
        router.push('/mortgage-application/checklistquotes');
        sendGaEvent('propertyAddressPage', 'mortgageRate');
        break;
      case state.matches('idle'):
        if (router.pathname.includes('/mortgage-application')) {
          router.push('/');
        }
        break;
      default:
        // throw new Error('The route for this state is not handled');
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);
  return <>{children}</>;
};

export default RouteHandler;
