import React, {
  ChangeEventHandler,
  FocusEventHandler,
  forwardRef,
  KeyboardEventHandler,
  ReactNode,
  RefObject,
  useState,
} from 'react';
import { IMaskInput } from 'react-imask';
import { buildInputMask } from 'src/utils';
import Label from 'components/commons/Label/Label';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './text-field.module.scss';

interface Mask {
  value: string;
  unmaskedValue: string;
  masked: {
    value: string;
  };
}

interface TextFieldProps {
  name: string;
  placeholder?: string;
  type?: string;
  label?: string;
  errorText?: string;
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  mask?: string;
  size?: 'small' | 'medium' | 'default';
  value?: string;
  defaultValue?: string;
  customClass?: string;
  wrapperCustomClass?: string;
  isCurrency?: boolean;
  onAccept?: (value: any, mask: Mask) => void;
  onKeyPress?: KeyboardEventHandler;
  labelExtraInfo?: string | React.ReactElement | null;
  isTextArea?: boolean;
  cypressTag?: string;
  register?: (name: any, config: {}) => void;
  validations?: {};
  rest?: any[];
  preventBlurStateChange?: Boolean;
  icon?: ReactNode;
  tooltip?: string;
  clearable?: boolean;
  onClear?: () => void;
  showLabel?: boolean;
}
const TextField = forwardRef<
  HTMLInputElement | HTMLTextAreaElement,
  TextFieldProps
>(
  (
    {
      name,
      placeholder,
      type,
      label,
      errorText,
      onChange,
      mask,
      size,
      value,
      defaultValue,
      customClass,
      wrapperCustomClass,
      isCurrency,
      onAccept,
      onKeyPress,
      labelExtraInfo,
      isTextArea,
      cypressTag,
      register,
      validations,
      preventBlurStateChange,
      showLabel,
      icon,
      tooltip,
      onClear,
      clearable,
      ...rest
    },
    ref
  ) => {
    const isSmall = size === 'small';
    const isMedium = size === 'medium';
    const [isFocus, setIsFocus] = useState<boolean>(false);

    const getField = () => {
      if (mask) {
        return (
          <IMaskInput
            value={value}
            mask={buildInputMask(mask)}
            unmask
            blocks={{ currency: { mask: Number, thousandsSeparator: ',' } }}
            id={name}
            data-testid={name}
            onAccept={(currentValue: any, currentMask: Mask) =>
              onAccept?.(currentValue, currentMask)
            }
            onKeyPress={onKeyPress}
            placeholder={placeholder}
            type={type}
            onChange={onChange}
            className={`${styles.textField}
            ${styles.textFieldDefault} 
          ${isSmall ? styles.textFieldSmall : ''} 
          ${isMedium ? styles.textFieldMedium : ''} 
          ${isCurrency ? styles.textFieldCurrency : ''}
          ${customClass}`}
            data-cy={cypressTag}
            ref={ref}
            {...rest}
            {...register?.(name, validations || {})}
          />
        );
      }
      if (isTextArea) {
        return (
          <textarea
            value={value}
            id={name}
            placeholder={placeholder}
            type={type}
            onChange={onChange}
            className={`${styles.textField} 
          ${styles.textFieldDefault} 
          ${customClass}`}
            rows={2}
            data-cy={cypressTag}
            ref={ref as RefObject<HTMLTextAreaElement>}
            {...rest}
            {...register?.(name, validations || {})}
          />
        );
      }
      return (
        <input
          defaultValue={defaultValue}
          autoComplete="off"
          value={value}
          id={name}
          placeholder={placeholder}
          type={type}
          onChange={onChange}
          className={`${styles.textField}
            ${styles.textFieldDefault} 
          ${isSmall ? styles.textFieldSmall : ''} 
          ${isMedium ? styles.textFieldMedium : ''} 
          ${isCurrency ? styles.textFieldCurrency : ''}
          ${customClass}`}
          data-cy={cypressTag}
          ref={ref as RefObject<HTMLInputElement>}
          {...rest}
          {...register?.(name, validations || {})}
        />
      );
    };
    return (
      <div className={`${styles.textfieldWrapper} ${wrapperCustomClass}`}>
        {isCurrency && (
          <span
            className={`${styles.currency} ${
              !showLabel && styles.currencyLabel
            }`}
          />
        )}
        <Label
          label={label}
          errorText={errorText}
          labelExtraInfo={labelExtraInfo}
          name={name}
          showLabel={showLabel}
          tooltip={tooltip}
        />
        <div
          className={`${styles.textFieldInputWrapper} ${
            isFocus ? styles.textFieldInputWrapperFocus : ''
          }`}
          onFocus={() => setIsFocus(true)}
          onBlur={() => {
            if (!preventBlurStateChange) {
              setIsFocus(false);
            }
          }}
        >
          {icon && <div className={`${styles.textFieldIcon} `}>{icon}</div>}
          {getField()}
          {clearable && (
            <button
              className={`${styles.textFieldIcon} ${styles.clearInput} `}
              onClick={onClear}
              type="button"
              aria-label="clear"
            >
              <FontAwesomeIcon
                className={styles.faArrowCircleRight}
                icon={faTimes}
              />
            </button>
          )}
        </div>
      </div>
    );
  }
);

TextField.defaultProps = {
  value: undefined,
  errorText: '',
  mask: '',
  size: 'default',
  label: '',
  customClass: '',
  wrapperCustomClass: '',
  isCurrency: false,
  onAccept: () => {},
  onKeyPress: () => {},
  onBlur: () => {},
  labelExtraInfo: null,
  isTextArea: false,
  placeholder: '',
  onChange: () => {},
  cypressTag: '',
};

export default TextField;
