import React, { useEffect } from 'react';
import { useFormMachine } from 'src/context/FormMachineProvider';
import { useActor } from '@xstate/react';
import sendGaEvent from 'src/utils/gtag';
import { useForm } from 'react-hook-form';
import TextField from 'components/commons/TextField/TextField';
import SecondaryButton from 'components/commons/SecondaryButton/SecondaryButton';
import { ActorRefFrom } from 'xstate';
import { emailPattern } from 'src/utils';
import Modal from 'components/commons/Modal/Modal';
import saveProgressMachine from 'src/machines/saveProgressMachine';
import styles from './save-progress.module.scss';

interface SaveProgressData {
  email: string;
}
const SaveProgress = () => {
  const [current] = useFormMachine();
  const { saveProgress } = current.context;

  const [currentSaveProgress, sendSaveProgress] = useActor<
    ActorRefFrom<typeof saveProgressMachine>
  >(saveProgress);

  const isLoading = currentSaveProgress
    ? currentSaveProgress.matches('loading')
    : false;

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = useForm<SaveProgressData>({
    mode: 'onChange',
    defaultValues: { email: '' },
  });

  useEffect(() => {
    sendGaEvent('emailModal', 'mortgageRate');
  }, []);

  const handleOnClick = handleSubmit(async (values: SaveProgressData) => {
    sendGaEvent('emailModalSubmit', 'mortgageRate');
    const { email: emailValue } = values;
    // Keeping the children saveProgress machine in the state causes erros when rehydrating the state
    const stateToSave = {
      // eslint-disable-next-line no-underscore-dangle
      _event: current._event,
      context: { ...current.context, activeModal: '', liens: [] },
      children: {},
      historyValue: current.historyValue,
      value: current.value,
    };

    const urlWithoutTrailingSlash = window.location.href.replace(/\/$/, '');
    const link = `${urlWithoutTrailingSlash}/?reference=${window.btoa(
      JSON.stringify(stateToSave)
    )}`;
    return sendSaveProgress({ type: 'SAVE_PROGRESS', email: emailValue, link });
  });

  const handleClose = () => {
    sendGaEvent('emailModalClose', 'mortgageRate');
    sendSaveProgress('CLOSE');
  };

  return (
    <Modal
      isOpen
      onRequestClose={handleClose}
      className={styles.emailModalWrapper}
    >
      <div className={styles.emailModalContainer}>
        <p className={styles.text}>We&apos;ll save your place!</p>
        <p className={styles.subText}>
          We&apos;ll email you a link so you can pick up where you left off.
        </p>

        <form onSubmit={handleOnClick}>
          <div>
            <div className={styles.fieldWrapper}>
              <TextField
                name="email"
                type="text"
                label="Enter your email"
                placeholder="email@email.com"
                cypressTag="saveProgressEmail"
                errorText={errors.email?.message}
                validations={{
                  required: 'Required',
                  pattern: {
                    value: emailPattern,
                    message: 'Invalid email address',
                  },
                }}
                register={register}
              />
            </div>
          </div>
          <SecondaryButton
            cypressTag="submitSaveProgress"
            disabled={!isValid || isSubmitting || isLoading}
            type="submit"
          >
            {isSubmitting || isLoading ? 'Saving progress' : 'Submit'}
          </SecondaryButton>
        </form>
      </div>
    </Modal>
  );
};

export default SaveProgress;
