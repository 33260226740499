import Modal from 'components/commons/Modal/Modal';
import React, { useEffect } from 'react';
import { useFormMachine } from 'src/context/FormMachineProvider';
import sendGaEvent from 'src/utils/gtag';

import styles from './flow-completed.module.scss';

const FlowCompleted = () => {
  const [state] = useFormMachine();
  const isKellerMortgage = state.context.jvInfo?.id === 102100;
  useEffect(() => {
    sendGaEvent('flowCompletedModal', 'mortgageRate');
  }, []);
  return (
    <Modal isOpen className={styles.container}>
      <h4 className={styles.title}>We&apos;ve received your pre-application</h4>
      <div className={styles.textContent}>
        {state.context.redirectUrl ? (
          <p>
            A loan officer will reach out to guide you through the rest of the
            application process.
          </p>
        ) : (
          <div>
            <p>
              Our support staff will reach out to you to help you continue the
              application process
              {isKellerMortgage ? ' or you can contact them directly:' : '.'}
            </p>
            {isKellerMortgage ? (
              <ul>
                <li>
                  Support Email:
                  <a
                    className={styles.call}
                    href="mailto:customer.support@kwlends.com"
                  >
                    {' '}
                    customer.support@kwlends.com
                  </a>
                </li>
                <li>
                  Support Phone #:
                  <a className={styles.call} href="tel:+1  833-429-8733">
                    {' '}
                    1 (833)-429-8733
                  </a>
                </li>
              </ul>
            ) : null}
          </div>
        )}
      </div>
      <p className={styles.canClose}>You can now close this window.</p>
    </Modal>
  );
};

export default FlowCompleted;
