import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import ReactModal from 'react-modal';
import styles from './modal.module.scss';

const Modal: React.FC<ReactModal.Props> = ({
  children,
  onRequestClose,
  overlayClassName,
  className,
  testId,
  ...rest
}) => {
  return (
    <ReactModal
      {...rest}
      className={`${styles.modal} ${className}`}
      overlayClassName={`${styles.overlay} ${overlayClassName}`}
      testId={testId}
    >
      <button
        className={styles.close}
        type="button"
        aria-label="Close modal"
        onClick={onRequestClose}
      >
        {onRequestClose && (
          <FontAwesomeIcon icon={faTimes} tabIndex={0} focusable name="close" />
        )}
      </button>
      {children}
    </ReactModal>
  );
};

export default Modal;
