import { FormContext } from 'src/common/types';
import { ActionFunctionMap, assign } from 'xstate';

export const buyOrRefiNode = {
  id: 'buyOrRefinance',
  initial: 'idle',
  on: {
    BACK: '#idle',
  },
  states: {
    idle: {
      on: {
        BUY: {
          target: 'buy',
          actions: 'setBuyOrRefiAnswer',
        },
        REFINANCE: {
          target: 'refinance.hist',
          actions: 'setBuyOrRefiAnswer',
        },
      },
    },
    refinance: {
      initial: 'idle',
      on: {
        BUY: {
          target: 'buy',
          actions: 'setBuyOrRefiAnswer',
        },
        DESELECT_ANSWER: {
          target: 'idle',
          actions: 'unsetBuyOrRefinance',
        },
        SET_UNIT: { actions: 'setUnit' },
      },
      states: {
        idle: {
          on: {
            SET_ADDRESS: { target: 'ready', actions: 'setAddress' },
          },
        },
        ready: {
          on: {
            NEXT: '#innerSteps.doesThisLookRight.hist',
            CLEAR_ADDRESS: { target: 'idle', actions: 'clearAddress' },
          },
        },
        hist: {
          type: 'history' as 'history',
        },
      },
    },
    buy: {
      on: {
        REFINANCE: {
          target: 'refinance.hist',
          actions: 'setBuyOrRefiAnswer',
        },
        DESELECT_ANSWER: {
          target: 'idle',
          actions: 'unsetBuyOrRefinance',
        },
        NEXT: '#innerSteps.homeType.hist',
      },
    },
    hist: {
      type: 'history' as 'history',
      history: 'deep' as 'deep',
    },
  },
};

export const buyOrRefiActions: ActionFunctionMap<FormContext, any> = {
  setBuyOrRefiAnswer: assign({
    answers: (context, event) => ({
      ...context.answers,
      buyOrRefinance: event.value,
    }),
  }),
  unsetBuyOrRefinance: assign({
    answers: (context, _event) => ({
      ...context.answers,
      buyOrRefinance: '' as '',
    }),
  }),
};
