import {
  GetAgentInfo,
  NoAgentContext,
  NoAgentSchema,
  NoAgentTransitions,
} from 'src/common/types';
import {
  Machine,
  sendParent,
  assign,
  MachineConfig,
  MachineOptions,
} from 'xstate';
import formContext from './context';

const noAgentMachineConfiguration: MachineConfig<
  NoAgentContext,
  NoAgentSchema,
  NoAgentTransitions
> = {
  id: 'noAgent',
  initial: 'unset',
  context: {
    answer: '',
    marketCenter: formContext.marketCenter,
  },
  states: {
    unset: {
      entry: sendParent({
        type: 'ACTIVATE_MODAL',
        modal: 'noKWAgent',
      }),
      on: {
        AGENT: {
          actions: 'setAnswer',
        },
        LISTING: {
          actions: 'setAnswer',
        },
        SKIP: {
          actions: 'setAnswer',
        },
        CANCEL: {
          target: 'finished',
        },
        CONTINUE: [
          { target: 'agent', cond: 'isAgent' },
          { target: 'finished', cond: 'isListing' },
          { target: 'skipped', cond: 'isSkipped' },
        ],
      },
    },
    agent: {
      entry: sendParent('ACTIVATE_HAS_AGENT'),
    },
    skipped: {
      entry: sendParent({
        type: 'ACTIVATE_MODAL',
        modal: 'skipAgent',
      }),
      on: {
        MISS_DISCOUNT: {
          target: 'finished',
        },
        BACK: {
          target: 'unset',
        },
      },
    },
    finished: {
      type: 'final',
      entry: sendParent({
        type: 'ACTIVATE_MODAL',
        modal: '',
      }),
      data: {
        answer: (context: NoAgentContext) => context.answer,
        selectedAgent: () => null,
        marketCenter: (context: NoAgentContext) => context.marketCenter,
      },
    },
  },
};

const noAgentMachineOptions: Partial<MachineOptions<NoAgentContext, any>> = {
  actions: {
    setAnswer: assign({
      answer: (_context, event) => event.answer,
      marketCenter: (_context, event) => event.marketCenter,
    }),
  },
  guards: {
    isAgent: context => context.answer === GetAgentInfo.HAS_AGENT,
    isListing: context => context.answer === GetAgentInfo.BUYING_FROM_AGENT,
    isOffice: context => context.answer === GetAgentInfo.HAS_MARKETCENTER,
    isSkipped: context => context.answer === GetAgentInfo.SKIPPED_KW_AGENT,
  },
};

const noAgentMachine = Machine(
  noAgentMachineConfiguration,
  noAgentMachineOptions
);

export default noAgentMachine;
