/* eslint-disable camelcase */
import { amortizationCalculator } from 'src/utils/amortization';
import { cleanObject, getState, mapHomeType, mapPropUse } from 'src/utils';
import {
  BuyOrRefinance,
  FormContext,
  HomeType,
  PropertyUse,
  StateToSave,
} from 'src/common/types';
import { formatPhoneNumber, convertToISOString } from './formats';

const mapPropType = (propertyType: HomeType | '') => {
  switch (propertyType) {
    case HomeType.SINGLE_FAMILY:
      return 'Single Family';
    case HomeType.CONDO:
      return 'Condominium';
    case HomeType.TOWNHOME:
      return 'Townhouse';
    case HomeType.MULTI_FAMILY:
      return 'Two to Four Unit Property';
    case HomeType.OTHER:
      return 'other';
    default:
      return '';
  }
};

const mapPropertyUse = (use: PropertyUse | '') => {
  switch (use) {
    case PropertyUse.PRIMARY:
      return 'PrimaryResidence';
    case PropertyUse.SECONDARY:
      return 'SecondHome';
    case PropertyUse.SEASONAL:
      return 'Secondary / Vacation';
    case PropertyUse.RENTAL:
      return 'Investment';
    default:
      return '';
  }
};

const mapTransType = (use: BuyOrRefinance | '') => {
  switch (use) {
    case BuyOrRefinance.BUY:
      return 'Purchase';
    case BuyOrRefinance.REFINANCE:
      return 'Refinance';
    default:
      return '';
  }
};

const determineLoanManagerFromPreApp = (
  loanFromPreApp: string,
  loanManagerId: number | ''
) => {
  if (
    loanFromPreApp ||
    (loanFromPreApp === '' && typeof loanManagerId === 'number')
  ) {
    return 1;
  }

  return null;
};

export const preApprovalPayload = (context: FormContext) => {
  const {
    address,
    answers,
    agent,
    marketCenter,
    userInfo,
    termsOfService,
    liens,
    rates,
    utm,
    flowInfo,
    team,
    jvInfo,
  } = context;
  let webToLead = {} as {
    affiliateAgency?: string;
    agentName?: string;
    agentEmail?: string;
    loanOfficerId?: string | null;
  };

  // if agent has agentAffiliateId, then it's an external agent
  if (agent.agencyAffiliation) {
    webToLead = {
      affiliateAgency: agent.agencyAffiliation,
      agentName: agent.name,
      agentEmail: agent.email,
      loanOfficerId: agent.loanManagerId
        ? agent.loanManagerId.toString()
        : null,
    };
  }
  return {
    PropAddress: address.address,
    PropCity: address.city,
    PropState: getState(address),
    PropZip: address.zip.number,
    state: getState(address),
    propCounty: address.county,
    propType: mapPropType(answers.homeType),
    PropUse: mapPropertyUse(answers.propertyUse),
    TransType: mapTransType(answers.buyOrRefinance),
    agentfirstname: agent.firstName,
    agentlastname: agent.lastName,
    loan_manager_id: agent.loanManagerId
      ? agent.loanManagerId.toString()
      : null,
    KWUID: agent.KWUID === 'invalid_kwuid' ? '' : agent.KWUID,
    AgentPhone: agent.phone,
    Leademail: agent.email,
    MCID: marketCenter.id.toString() || '',
    MCname: marketCenter.name,
    first_name: userInfo.firstName,
    last_name: userInfo.lastName,
    email: userInfo.email,
    mobile_number: userInfo.phone,
    is_terms_agreed: termsOfService.isAccepted,
    Timeframe: Number(answers.purchaseDate),
    Lien1: amortizationCalculator(
      liens[0]?.amount,
      liens[0]?.record_date,
      liens[0]?.mortgage_years,
      liens[0]?.hc_interest_rate
    ),
    L1TypeCredit: liens[0]?.lien_type,
    L1Rate: liens[0]?.hc_interest_rate,
    L1Term: liens[0]?.mortgage_years,
    L1AmtFinanced: liens[0]?.amount,
    L1LenderType: liens[0]?.lender_type,
    L1RecordDate: liens[0]?.record_date,
    L1DueDate: liens[0]?.due_date,
    Lien2: amortizationCalculator(
      liens[1]?.amount,
      liens[1]?.record_date,
      liens[1]?.mortgage_years,
      liens[1]?.hc_interest_rate
    ),
    L2TypeCredit: liens[1]?.lien_type,
    L2Rate: liens[1]?.hc_interest_rate,
    L2Term: liens[1]?.mortgage_years,
    L2AmtFinanced: liens[1]?.amount,
    L2LenderType: liens[1]?.lender_type,
    L2RecordDate: liens[1]?.record_date,
    L2DueDate: liens[1]?.due_date,
    Lien3: amortizationCalculator(
      liens[2]?.amount,
      liens[2]?.record_date,
      liens[2]?.mortgage_years,
      liens[2]?.hc_interest_rate
    ),
    L3TypeCredit: liens[2]?.lien_type,
    L3Rate: liens[2]?.hc_interest_rate,
    L3Term: liens[2]?.mortgage_years,
    L3AmtFinanced: liens[2]?.amount,
    L3LenderType: liens[2]?.lender_type,
    L3RecordDate: liens[2]?.record_date,
    L3DueDate: liens[2]?.due_date,
    EstCurVal: answers.askingPrice,
    FirstTimeBuyer: 'false', // no longer neccesary
    RateDisplayed: rates.kwRate,
    TermDisplayed: rates.loanTerm,
    SalesPriceDisplayed: rates.salesPayment,
    DownPaymentDisplayed: rates.downPayment,
    DPPercentDisplayed: rates.downPaymentPercent,
    creditscorerange: rates.creditsCoreRange,
    utm_source: utm.source,
    utm_medium: utm.medium !== '' ? utm.medium : 'NEW2',
    utm_campaign: utm.campaign,
    utm_term: utm.term,
    utm_content: utm.content,
    campaign_id: Number(flowInfo.campaignID) || 46,
    is_buyer_agent: flowInfo.isBuyerAgent,
    is_seller_agent: flowInfo.isSellerAgent,
    team_id: team.teamID,
    team_name: team.teamName,
    team_phone: team.teamPhone,
    team_email: team.teamEmail,
    loan_mgr_from_preapp: determineLoanManagerFromPreApp(
      flowInfo.loanFromPreApp,
      agent.loanManagerId
    ),
    SessionID: Number.isInteger(flowInfo.sessionID) ? flowInfo.sessionID : null,
    PartnerID: jvInfo.id,
    webToLead,
  };
};

export const sessionPayload = (
  context: FormContext,
  step?: string,
  stateToSave?: StateToSave | null
) => {
  const {
    address,
    answers,
    agent,
    marketCenter,
    userInfo,
    termsOfService,
    liens,
    rates,
    utm,
    flowInfo,
    team,
    propAddressOrNot,
    jvInfo,
  } = context;
  const initSessionPayload = {
    PropAddress: address.address,
    PropCity: address.city,
    PropState: getState(address),
    PropZip: address.zip.number,
    state: getState(address),
    propCounty: address.county,
    propType: mapPropType(answers.homeType),
    PropUse: mapPropertyUse(answers.propertyUse),
    TransType: mapTransType(answers.buyOrRefinance),
    agentfirstname: agent.firstName,
    agentlastname: agent.lastName,
    loan_manager_id: agent.loanManagerId
      ? agent.loanManagerId.toString()
      : null,
    KWUID:
      agent.KWUID === 'invalid_kwuid' || agent.KWUID === ''
        ? ''
        : Number(agent.KWUID),
    AgentPhone: agent.phone,
    Leademail: agent.email,
    MCID: marketCenter.id,
    MCname: marketCenter.name,
    first_name: userInfo.firstName,
    last_name: userInfo.lastName,
    email: userInfo.email,
    mobile_number: userInfo.phone,
    is_terms_agreed: termsOfService.isAccepted,
    Timeframe: Number(answers.purchaseDate),
    Lien1: amortizationCalculator(
      liens[0]?.amount,
      liens[0]?.record_date,
      liens[0]?.mortgage_years,
      liens[0]?.hc_interest_rate
    ),
    L1TypeCredit: liens[0]?.lien_type,
    L1Rate: liens[0]?.hc_interest_rate,
    L1Term: liens[0]?.mortgage_years,
    L1AmtFinanced: liens[0]?.amount,
    L1LenderType: liens[0]?.lender_type,
    L1RecordDate: convertToISOString(liens[0]?.record_date),
    L1DueDate: convertToISOString(liens[0]?.due_date),
    Lien2: amortizationCalculator(
      liens[1]?.amount,
      liens[1]?.record_date,
      liens[1]?.mortgage_years,
      liens[1]?.hc_interest_rate
    ),
    L2TypeCredit: liens[1]?.lien_type,
    L2Rate: liens[1]?.hc_interest_rate,
    L2Term: liens[1]?.mortgage_years,
    L2AmtFinanced: liens[1]?.amount,
    L2LenderType: liens[1]?.lender_type,
    L2RecordDate: convertToISOString(liens[1]?.record_date),
    L2DueDate: convertToISOString(liens[1]?.due_date),
    Lien3: amortizationCalculator(
      liens[2]?.amount,
      liens[2]?.record_date,
      liens[2]?.mortgage_years,
      liens[2]?.hc_interest_rate
    ),
    L3TypeCredit: liens[2]?.lien_type,
    L3Rate: liens[2]?.hc_interest_rate,
    L3Term: liens[2]?.mortgage_years,
    L3AmtFinanced: liens[2]?.amount,
    L3LenderType: liens[2]?.lender_type,
    L3RecordDate: convertToISOString(liens[2]?.record_date),
    L3DueDate: convertToISOString(liens[2]?.due_date),
    EstCurVal: parseInt(answers.askingPrice, 10),
    FirstTimeBuyer: 'false', // no longer neccesary
    RateDisplayed: rates.kwRate,
    TermDisplayed: rates.loanTerm,
    SalesPriceDisplayed: rates.salesPayment,
    DownPaymentDisplayed: rates.downPayment,
    DPPercentDisplayed: rates.downPaymentPercent,
    creditscorerange: rates.creditsCoreRange,
    utm_source: utm.source,
    utm_medium: utm.medium !== '' ? utm.medium : 'NEW2',
    utm_campaign: utm.campaign,
    utm_term: utm.term,
    utm_content: utm.content,
    campaign_id: flowInfo.campaignID || 46,
    is_buyer_agent: flowInfo.isBuyerAgent,
    is_seller_agent: flowInfo.isSellerAgent,
    isLeadSubmitted: flowInfo.isLeadSubmitted,
    propAddressOrNot,
    team_id: team.teamID,
    team_name: team.teamName,
    team_phone: team.teamPhone,
    team_email: team.teamEmail,
    loan_mgr_from_preapp: determineLoanManagerFromPreApp(
      flowInfo.loanFromPreApp,
      agent.loanManagerId
    ),
    step,
    restoreToken: stateToSave ? window.btoa(JSON.stringify(stateToSave)) : '',
    partnerId: jvInfo.id,
  };
  const data = {
    name: 'mortgage-session',
    service: 'km-mortgage-session',
    operation: 'create',
    payload: {
      ...cleanObject(initSessionPayload),
    },
  };
  return data;
};

export const beaconPayload = (context: FormContext) => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo')!);
  const { firstName, lastName, phone, email, state } = userInfo;
  const original = sessionPayload(context);
  const newPayload = {
    name: 'mortgage-session',
    service: 'km-mortgage-session',
    operation: 'create',
    payload: {
      ...original.payload,
      email,
      state: state === '' ? getState(context.address) : state,
      mobile_number: phone,
      first_name: firstName,
      last_name: lastName,
    },
  };
  return newPayload;
};

export const contactPayload = (context: FormContext) => {
  const {
    address: {
      address,
      city,
      state,
      county,
      street,
      streetNumber,
      zip: { number },
    },
    userInfo: { email, firstName, lastName, phone, dateOfBirth },
    answers: { homeType, propertyUse },
    dwellingDetails: {
      noOfStories,
      yearBuilt,
      squareFeet,
      totalBathCount,
      numberOfBedrooms,
    },
  } = context;

  const currentPayload = {
    agentId: null,
    bathrooms: totalBathCount,
    bedrooms: numberOfBedrooms,
    campaign: null,
    currentAddress: address,
    currentCity: city,
    currentCounty: county,
    currentState: state,
    currentStreet: street,
    currentStreetNumber: streetNumber,
    currentZipcode: number,
    dateOfBirth: convertToISOString(dateOfBirth),
    email,
    firstName,
    lastName,
    lastVisitedStep: null,
    lead: 'ORGANIC',
    loanOfficerId: null,
    occupancyType: 'OWNER',
    ownsProperty: 'Buying home',
    phone: formatPhoneNumber(phone),
    policyId: null,
    propertyType: mapHomeType(homeType),
    squareFeet,
    stories: noOfStories,
    usageType: mapPropUse(propertyUse),
    yearBuilt,
  };
  return currentPayload;
};

export const quotePayload = (context: FormContext) => {
  const {
    address: {
      city,
      state,
      county,
      street,
      streetNumber,
      unit,
      zip: { number },
    },
    userInfo: { email, firstName, lastName, phone },
    agent: { loanManagerId },
    answers: { homeType, propertyUse },
    dwellingDetails: {
      noOfStories,
      yearBuilt,
      squareFeet,
      replacementCost,
      fireHydrantDistanceAnswer,
      fireStationDistanceAnswer,
    },
  } = context;

  const currentPayload = {
    city,
    county,
    current_address_unit: unit,
    current_city: city,
    current_county: county,
    current_postal_code: number,
    current_state: state,
    current_street: street,
    current_street_number: streetNumber,
    email,
    fire_hydrant_distance_answer: fireHydrantDistanceAnswer,
    fire_station_distance_answer: fireStationDistanceAnswer,
    first_name: firstName,
    last_name: lastName,
    loan_officer_id: loanManagerId,
    marital_status: '',
    occupancy_type: 'OWNER',
    owns_property: 'Buying home',
    phone: formatPhoneNumber(phone),
    phone_number: formatPhoneNumber(phone),
    postal_code: number,
    property_type: mapHomeType(homeType),
    replacement_cost: replacementCost,
    square_feet: squareFeet,
    state,
    stories: noOfStories,
    street,
    street_number: streetNumber,
    usage_type: mapPropUse(propertyUse),
    year_built: yearBuilt,
    date_of_birth: '1992-11-11T00:00:00.000Z',
  };
  return currentPayload;
};

export const hubspotQuotePayload = (context: FormContext) => {
  const {
    address: {
      address,
      city,
      state,
      zip: { number },
    },
    userInfo: { email, firstName, lastName, phone },
    agent: {
      photo,
      loanManagerFirstName,
      loanManagerLastName,
      loanManagerEmail,
      firstName: agentFirstName,
      lastName: agentLastName,
      email: agentEmail,
      phone: agentPhone,
    },
    kellerCovered: { sessionID, updatedAt },
    quotes: {
      quoteSelected: {
        carrier_name,
        annual_premium_quote,
        coverage: {
          base_deductible,
          loss_of_use,
          medical_payments_to_others,
          personal_liability,
          personal_property,
          dwelling_limit,
        },
      },
      saveForLater,
    },
  } = context;
  const currentPayload = {
    remindMeLater: saveForLater,
    address,
    city,
    state,
    zip: number,
    firstName,
    lastName,
    phone: formatPhoneNumber(phone),
    email,
    agentName: `${agentFirstName} ${agentLastName}`,
    agentEmail,
    agentPhone,
    agentImage: photo,
    loanOfficerName: `${loanManagerFirstName} ${loanManagerLastName}`,
    loanOfficerEmail: loanManagerEmail,
    onwsHome: 'Own home',
    carrier: carrier_name,
    insuranceQuote: String(annual_premium_quote),
    deductible: String(base_deductible.value),
    lossOfUse: String(loss_of_use.value),
    medicalPayment: String(medical_payments_to_others.value),
    personalLiability: String(personal_liability.value),
    personalProperty: String(personal_property.value),
    replacementCost: String(dwelling_limit.value),
    sessionId: sessionID,
    lastUpdate: updatedAt,
  };
  return currentPayload;
};
