import NoAgentModal from 'components/mortgage-application/getAgentInfo/no-kw-agent/initial-modal/NoAgentModal';
import SkipAgentSelectionModal from 'components/mortgage-application/getAgentInfo/no-kw-agent/skip-agent-selection/SkipAgentSelectionModal';
import { useRouter } from 'next/router';
import React from 'react';
import { useFormMachine } from 'src/context/FormMachineProvider';
import AgentSearchModal from 'components/mortgage-application/modals/AgentSearchModal/AgentSearchModal';
import AgentResultsModal from 'components/mortgage-application/modals/AgentResultsModal/AgentResultsModal';
import AgentNotFound from 'components/mortgage-application/modals/AgentNotFoundModal/AgentNotFoundModal';
import SaveProgress from 'components/mortgage-application/modals/SaveProgress/SaveProgress';
import SaveConfirmationModal from 'components/mortgage-application/modals/SaveConfirmationModal/SaveConfirmationModal';
import LoadingModal from '../Modals/LoadingModal/LoadingModal';
import ErrorModal from '../Modals/ErrorModal/ErrorModal';
import FullPageLoader from '../Modals/FullPageLoader/FullPageLoader';
import FlowCompleted from '../Modals/FlowCompled/FlowCompleted';

const ModalsHandler = () => {
  const [current] = useFormMachine();
  const {
    context: { activeModal },
  } = current;

  const router = useRouter();

  // check if is in any step of the flow.
  const onMortgageApp = router.pathname.includes('mortgage-application');
  const showModal =
    activeModal === 'loading' ||
    activeModal === 'fullPageLoader' ||
    onMortgageApp;

  const getActiveModal = () => {
    switch (activeModal) {
      case 'loading':
        return <LoadingModal />;
      case 'error':
        return <ErrorModal />;
      case 'agentSearch':
        return <AgentSearchModal />;
      case 'agentResults':
        return <AgentResultsModal />;
      case 'agentNotFound':
        return <AgentNotFound />;
      case 'noKWAgent':
        return <NoAgentModal />;
      case 'skipAgent':
        return <SkipAgentSelectionModal />;
      case 'fullPageLoader':
        return <FullPageLoader />;
      case 'flowCompleted':
        return <FlowCompleted />;
      case 'saveProgress':
        return <SaveProgress />;
      case 'saveProgressConfirmation':
        return <SaveConfirmationModal />;
      default:
        return null;
    }
  };

  return (getActiveModal() && showModal) ||
    activeModal === 'contactUsConfirmation'
    ? getActiveModal()
    : null;
};

export default ModalsHandler;
