import { FormContext, PropAddress, PropertyAddress } from 'src/common/types';
import { ActionFunctionMap, assign } from 'xstate';
import formContext from '../context';

export const propertyAddressNode = {
  id: 'propertyAddress',
  initial: 'idle',
  on: {
    BACK: '#steps.innerSteps.propertyUse.hist',
  },
  states: {
    idle: {
      on: {
        YES: { target: 'yes.hist', actions: 'setYes' },
        NO: { target: 'no', actions: 'setNo' },
      },
    },
    yes: {
      initial: 'idle',
      on: {
        NO: { target: 'no.hist', actions: 'setNo' },
        SET_UNIT: { actions: 'setUnit' },
      },
      states: {
        idle: {
          on: {
            SET_ADDRESS: [
              {
                target: 'ready',
                actions: 'setAddress',
                cond: 'validAddress',
              },
              {
                actions: 'clearAddressAndUnit',
              },
            ],
          },
        },
        ready: {
          on: {
            NEXT: '#innerSteps.purchaseDate.hist',
            CLEAR_ADDRESS: { target: 'idle', actions: 'clearAddressAndUnit' },
          },
        },
        hist: { type: 'history' as 'history' },
      },
    },
    no: {
      initial: 'ready',
      on: {
        YES: { target: 'yes', actions: 'setYes' },
        CLEAR_ZIP: { target: 'no.idle', actions: 'clearZip' },
      },
      states: {
        idle: {
          on: {
            SET_ZIP: { target: 'ready', actions: 'setZip' },
            NEXT: '#innerSteps.purchaseDate.hist',
          },
        },
        ready: {
          on: { NEXT: '#innerSteps.purchaseDate.hist' },
        },
        hist: { type: 'history' as 'history' },
      },
    },
    hist: {
      type: 'history' as 'history',
      history: 'deep' as 'deep',
    },
  },
};

export const propertyAddressActions: ActionFunctionMap<FormContext, any> = {
  setYes: assign({
    propAddressOrNot: _context => PropAddress.YES,
    answers: ctx => ({ ...ctx.answers, propertyAddress: PropertyAddress.YES }),
  }),
  setNo: assign({
    propAddressOrNot: _context => PropAddress.NO_WITHOUT_ZIP,
    // remove any possible selected address (for example if user chose refinance first, then went back and chose buy)
    answers: ctx => ({ ...ctx.answers, propertyAddress: PropertyAddress.NONE }),
    address: _context => formContext.address,
  }),
  setZip: assign({
    propAddressOrNot: _context => PropAddress.NO_WITH_ZIP,
    address: (_context, event) => ({
      ...formContext.address,
      zip: { number: event.zip, valid: true },
      state: event.state,
    }),
  }),
  clearZip: assign({
    propAddressOrNot: _context => PropAddress.NO_WITHOUT_ZIP,
    address: _context => formContext.address,
  }),
};
