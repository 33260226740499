export const whatIsYourCurrentAddressNode = {
  initial: 'idle',
  states: {
    idle: {
      on: {
        SET_ADDRESS: { target: 'ready', actions: 'setAddress' },
      },
    },
    ready: {
      on: {
        CLEAR_ADDRESS: { actions: 'clearAddress', target: 'idle' },
        NEXT: '#innerSteps.doesThisLookRight.hist',
      },
    },
    hist: {
      type: 'history' as 'history',
    },
  },
  on: {
    BACK: '#idle',
    SET_UNIT: { actions: 'setUnit' },
  },
};
