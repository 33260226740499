import { FormContext, FormEvent } from 'src/common/types';
import { sendQuoteToHubspot } from 'src/utils/fetchActions';
import { ActionFunctionMap, assign, MachineConfig } from 'xstate';

export const quotesNode: MachineConfig<FormContext, any, FormEvent> = {
  id: 'quotes',
  initial: 'checklistQuotes',
  entry: { type: 'changeModal', modal: '' },
  on: {
    BACK: '#steps.innerSteps.disclaimer',
    SELECT_QUOTE: {
      actions: 'selectQuote',
    },
    NEXT: [
      {
        target: '#quotes.submitDataToHubspot',
        cond: 'hasSelectedQuote',
      },
      {
        target: '#submitData',
      },
    ],
  },
  states: {
    checklistQuotes: {
      on: {
        CHECKLIST_NEXT: 'quotes',
      },
    },
    quotes: {
      on: {
        BACK: 'checklistQuotes',
        BACK_TO_CHECKLIST: 'checklistQuotes',
      },
    },
    submitDataToHubspot: {
      entry: { type: 'changeModal', modal: 'loading' },
      invoke: {
        id: 'submitDataToHubspotCall',
        src: context => sendQuoteToHubspot(context),
        onDone: {
          target: '#submitData',
        },
        onError: {
          target: '#submitData',
        },
      },
    },
    hist: {
      type: 'history' as 'history',
    },
  },
};

export const quotesActions: ActionFunctionMap<FormContext, any> = {
  selectQuote: assign((context, event) => ({
    ...context,
    quotes: {
      ...context.quotes,
      quoteSelected: event.ownsInsurance ? null : event.quote,
      ownsHomeInsurance: event.ownsInsurance || false,
    },
  })),
};

export const quotesGuards = {
  hasSelectedQuote: (context: FormContext) =>
    context.quotes.quoteSelected !== null,
};
