import React from 'react';

const TooltipLeftArrow = ({ classes }: { classes: string }) => {
  return (
    <svg
      width="6"
      height="8"
      viewBox="0 0 6 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes}
    >
      <path d="M6 0L6 8L0 4L6 0Z" fill="black" />
    </svg>
  );
};

export default TooltipLeftArrow;
