import React, { useEffect } from 'react';
import { useActor } from '@xstate/react';
import Modal from 'components/commons/Modal/Modal';
import PrimaryButton from 'components/commons/PrimaryButton/PrimaryButton';
import SecondaryButton from 'components/commons/SecondaryButton/SecondaryButton';
import { useFormMachine } from 'src/context/FormMachineProvider';
import sendGaEvent from 'src/utils/gtag';
import styles from './skip-agent-selection-modal.module.scss';

const SkipAgentSelectionModal = () => {
  const [current] = useFormMachine();
  const [getAgentInfoMachine] = useActor(current.children.getAgentInfo);
  const [, sendNoAgent] = useActor(
    (getAgentInfoMachine as any).children.noAgent
  );

  useEffect(() => {
    sendGaEvent('skipAgentSelectionModal', 'mortgageRate');
  }, []);

  const handleSkip = () => {
    sendGaEvent('skipAgentSelectionClose', 'mortgageRate');
    sendNoAgent('MISS_DISCOUNT');
  };

  const handleApplyForZeroPlus = () => {
    sendGaEvent('skipAgentSelectionApplyForZeroPlus', 'mortgageRate');
    sendNoAgent('BACK');
  };

  return (
    <Modal
      onRequestClose={handleApplyForZeroPlus}
      isOpen
      className={styles.dialog}
      contentLabel="Skip savings"
    >
      <div className={styles.skipAgentSelectionModalWrapper}>
        <p className={styles.text}>
          Are you sure you want to miss out on an average of <span>$3,750</span>{' '}
          in savings?
        </p>
        <p className={styles.subText}>
          That’s right, with an average home loan of $250,000, customers save an
          average of $3,750 just on closing costs with the ZeroPlus loan
          program. To qualify, you must work with a Keller Williams agent, a
          Keller Williams office, or purchase a Keller Williams listing.
        </p>
        <div className={styles.actionButtons}>
          <SecondaryButton handleOnClick={handleSkip} cypressTag="skip">
            Skip and miss out on savings
          </SecondaryButton>
          <PrimaryButton
            handleOnClick={handleApplyForZeroPlus}
            cypressTag="choose"
          >
            Choose an agent
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  );
};

export default SkipAgentSelectionModal;
