import React from 'react';
import { AgentResponse } from 'src/common/apiSchemas';
import sendGaEvent from 'src/utils/gtag';
import styles from './agent-card.module.scss';

const placeholder = '/img/mortgage-application/defaultAgentImage.svg';

const AgentCard = ({
  agent,
  handleSelect,
}: {
  agent: AgentResponse;
  handleSelect: (agent: AgentResponse) => void;
}) => {
  const selectAgent = () => {
    sendGaEvent('agentResultsChoseAgent', 'mortgageRate');
    handleSelect(agent);
  };

  const agentImage =
    agent && agent.photo ? agent.photo.replace(/ /g, '%20') : placeholder; // replace spaces with encoded character

  return (
    <div
      className={styles.agentCard}
      onClick={selectAgent}
      onKeyPress={e => e.key === 'enter' && selectAgent}
      data-cy="myAgent"
      role="button"
      tabIndex={0}
      aria-label={`${agent.first_name} ${agent.last_name}`}
    >
      <div className={styles.agentImageIcon}>
        <img
          className={styles.agentPortrait}
          src={agentImage}
          alt={`${agent.first_name}_${agent.last_name}`}
          onError={e => {
            (e.target as HTMLImageElement).src = placeholder;
          }}
        />
      </div>
      <div className={styles.agentDescriptionContainer}>
        <div className={styles.agentNameContainer}>
          <p className={styles.agentName}>
            {agent.first_name.toLowerCase()} {agent.last_name.toLowerCase()}
          </p>
          <p className={styles.agentLocation}>
            {agent.city && agent.state
              ? `${agent.city.toLowerCase()}, ${agent.state}`
              : ''}
          </p>
        </div>
        <p className={styles.agentTag}>Agent</p>
      </div>
    </div>
  );
};

export default AgentCard;
