export const theme = {
  colors: {
    navy: '#1A2136',
    navyLight: '#5A6483',
    lightNavy: '#5A6483',

    green: '#20a85f',
    lightGreen: '#aae2c3',
    lightGreen2: '#9AD8B5',
    lightGreen3: '#E6F6ED',
    darkGreen: '#29454D',

    darkGray: '#8d8d8d',
    gray: '#dddddd',
    lightGray: '#fafafa',
    mediumGray: '#dddddd',
    navyGray: '#E4E6EC',

    black: '#191919',
    black2: '#000000',

    white: '#ffffff',

    red: '#CE011F',
  },

  shadows: {
    default: '0px 12px 48px rgba(0,0,0,0.1)',
    small: '0px 6px 24px rgba(0, 0, 0, 0.05)',
  },

  breakpoints: {
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xxl: '1440px',
    xxxl: '1680px',
  },
};
