import {
  sendParent,
  assign,
  MachineConfig,
  MachineOptions,
  createMachine,
} from 'xstate';
import {
  AgentInfoContext,
  AgentInfoSchema,
  AgentInfoTransitions,
  GetAgentInfo,
} from 'src/common/types';
import searchAgentMachine from './searchAgentMachine';
import noAgentMachine from './noAgentMachine';
import formContext from './context';

const agentInfoMachineConfiguration: MachineConfig<
  AgentInfoContext,
  AgentInfoSchema,
  AgentInfoTransitions
> = {
  id: 'agentInfo',
  initial: 'unset',
  context: {
    answer: null,
    selectedAgent: null,
    marketCenter: null,
    campaignID: null,
  },
  states: {
    unset: {
      on: {
        HAS_AGENT: 'hasAgent',
        NO_AGENT: 'noAgent',
      },
    },
    hasAgent: {
      invoke: {
        id: 'agentSearch',
        autoForward: true,
        src: 'searchAgentMachine',
        onDone: {
          target: 'finished',
          actions: 'selectAgent',
        },
      },
      on: {
        CANCEL_HAS_AGENT: {
          target: 'unset',
          actions: sendParent({
            type: 'ACTIVATE_MODAL',
            modal: '',
          }),
        },
      },
    },
    noAgent: {
      invoke: {
        id: 'noAgent',
        src: 'noAgentMachine',
        onDone: {
          target: 'finished',
          actions: 'setAnswer',
        },
      },
      on: {
        CANCEL_NO_AGENT: {
          target: 'unset',
          actions: sendParent({
            type: 'ACTIVATE_MODAL',
            modal: '',
          }),
        },
      },
    },
    finished: {
      type: 'final',
      data: {
        answer: (context: AgentInfoContext) => context.answer,
        selectedAgent: (context: AgentInfoContext) => context.selectedAgent,
        marketCenter: (context: AgentInfoContext) => context.marketCenter,
        campaignID: (context: AgentInfoContext) => context.campaignID,
      },
    },
  },
  on: {
    BACK: { actions: sendParent('NAVIGATE_TO_HOME') },
    ACTIVATE_MODAL: { actions: 'activateModal' },
    ACTIVATE_HAS_AGENT: { target: 'hasAgent' },
  },
};

const agentInfoMachineOptions: Partial<
  MachineOptions<AgentInfoContext, any>
> = {
  actions: {
    selectAgent: assign((context, event) => ({
      ...context,
      selectedAgent: event.data.selectedAgent,
      marketCenter: event.data.marketCenter,
      answer: GetAgentInfo.HAS_AGENT,
      campaignID: 45,
    })),
    setAnswer: assign((context, event) => ({
      ...context,
      answer: event.data.answer,
      marketCenter: event.data.marketCenter
        ? event.data.marketCenter
        : formContext.marketCenter,
      selectedAgent: formContext.agent,
      campaignID: 46,
    })),
    activateModal: sendParent((_, event) => ({
      type: 'CHANGE_MODAL',
      modal: event.modal,
    })),
  },
  services: {
    searchAgentMachine,
    noAgentMachine,
  },
};

const agentInfoMachine = createMachine(
  agentInfoMachineConfiguration,
  agentInfoMachineOptions
);

export default agentInfoMachine;
